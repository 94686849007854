.collapse {
    position: relative;
  
    &__sidemenu {
      max-height: 33%;
      height: 100%;
      position: fixed;
      z-index: 11;
      background-color: #24323b;
      // padding: 20px 10px 20px 15px;
      transform: translateY(300%);
      transition: 0.8s ease;
  

      // Sidemenu search bar
      & .searchbar__container { padding: 15px 0 7px 0; }
      & .searchbar__icon,
      & .input__field { height: 36px; margin-bottom: 0; }
  
      // Searchbar placeholder text
      & .input__field { 
        font-size: 14px; 
        opacity: 0.5;
      }
  
      // If sidemenu is open
      &--open { transform: translateY(-100%); }
      &--open .collapse__side-btn svg { transform: rotate(180deg); }
    }
  
    // Header
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 55px;
      padding: 0 15px;
      background-color: #2f404a;
      border-bottom: 1px solid #16242c;
    }
  
    // Container for searchbar and cards
    &__container { 
    padding: 1px 15px 32px 15px;
    height: 85%;
    }
  
    // Toggle collapse sidemenu Button
    &__side-btn {
      position: absolute;
      left: -105px;
      top: 220px;
      background-color: #0287cc;
      max-width: 170px;
      width: 100%;
      height: 43px;
      z-index: -1;
      border-radius: 3px;
      transform: rotate(-90deg);
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
  
      & svg {
        width:  6px;
        height: 16px;
        transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        transition-delay: 0.1s;
        transform: rotate(90deg);
  
        & path { fill: #0287cc; }
      }
    }
  
    // collapse sidemenu cards
    & .card { 
      min-height: 0; 
      margin: 10px 0;
  
      // Card text
      & p { font-size: 12px; }
      
      // collapse sidemenu cards buttons
      & .btn { 
        margin: 10px 0 0 0; 
        font-size: 12px;
  
        & svg { 
          width:  14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  
  }
  .resize_button {
    position: absolute;
    top: 14px;
    right: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    max-width:    28px;
    width:        100%;
    min-height:   28px;
    border-radius: 50%;
    background-color: #a5b8ca;
    font-size: 12px;
    transition: $transition;
    cursor: pointer;
    z-index: 11;
    fill: #181818;
    
      &:hover { 
        background-color: #16242c;
        fill: white !important;
        color:white;
      }
      img {
        margin-right: 20%;
        max-width: 59%;
        height: auto;
        float: right;
      
      }
    }

  .resize{
    max-height: 63% !important;
    padding: 0 -4px 79px 15px;
    width: 998px;
  svg:hover{
    color:white;
  }
  }
  

  // Close button on modal
  .close {
    position: absolute;
    top:   14px;
    right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

   
    // padding-bottom: 2px;
    max-width:    28px;
    width:        100%;
    min-height:   28px;
    border-radius: 50%;
    background-color: #a5b8ca;
    font-size: 12px;
    transition: $transition;
    cursor: pointer;
    z-index: 11;

    &:hover { 
      background-color: #16242c;
      color: $txt-white;
    }
  }

// Tabos on collapse
  .tab-collapse {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // padding: 5px 0;
    width: calc(100% + 30px);
    margin-left: -15px;  
    // border-bottom: 1px solid #222;
    position: relative;
  
    &__item {
        margin: 0 10px;
        padding: 3px 10px;
        border-radius: 3px;
        transition: 0.1s ease;
        cursor: pointer;
        text-transform: uppercase;
        transition: 0.5s ease;
    
        &:hover {
            background-color: #2f404a;
            color: #fff;
        }
  
        &.active { 
          background-color: #24323b;
          color: #fff;
      }
      
    }
  
    // Components / Microservices lists tab menu
    &--big {
      justify-content: flex-start;
      border-bottom: none;
      margin-left: -45px;
      padding: 0;
  
      .tab-collapse__item {
        position: relative;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        padding: 15px 30px 25px;
        text-transform: capitalize;
        
        &:hover,
        &.active { background: none; }
        
        &.active::before {
          content: "";
          position: absolute;
          bottom: -4px;
          left:   0;
          right:  0;
          height: 3px;
          width: 100%;
          background-color: $blue;
          transition: $transition;
          opacity: 1;
        }
  
        // &.active::before { opacity: 1; }
  
        // &:hover::before { opacity: 1; }
      }
    }
  
    // Create Stage Deployment tab menu
    &--deployment {
      justify-content: flex-start;
      background-color: #2f404a;
      color: #a5b8ca;
      text-align: center;
      width: 100%;
      margin-left: 0;
  
      .tab-collapse__item {
        position: relative;
        font-size: 15px;
        text-transform: capitalize;
        padding: 13px 20px;
        
        font-weight: bold;
        margin: 0;
  
        // &.active::before {
        //   content: "";
        //   position: absolute;
        //   bottom: -6px;
        //   left: 0;
        //   right: 0;
        //   height: 3px;
        //   background-color: $blue;
        //   transition: $transition;
        //   opacity: 0;
        // }
  
        &.active::before { opacity: 1; }
  
        &:hover::before  { opacity: 1; }
      }
    }
  }
