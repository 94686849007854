.list {
  width: 100%;
  // max-height: 320px;
  // overflow-y: auto;
  padding: 8px 10px 8px 10px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    background-color: #2f404a;
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 10px;
    box-shadow: 0 0 0 rgba(0,0,0,0.3);
    transition: $transition;

    &:hover { box-shadow: 0 8px 8px rgba(0,0,0,0.3); }
    
  }

  &__text {
    font-family: "Lato";

    h5 { font-size: 16px; }

    p { 
      font-size: 12px; 
      // text-transform: uppercase;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: $transition;

    * { 
      margin: 0 5px;
      cursor: pointer;
    }

  }

  // &__item:hover &__icons { opacity: 1;}

  // Full height lists
  &--height { 
    // min-height: 160px;  
    min-height: calc(100vh - 390px);
    max-height: calc(100vh - 350px);
  }

  // Settings screen management lists
  &__management {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    // List columns
    & .list__column {
      width: 100%;
      word-break: break-word;

      &:first-of-type  { max-width: 240px; }
      &:nth-of-type(2) { max-width: 350px; }
    }

    // Add margin on everything except the image circles
    *:not(:first-child) { margin: 0 30px; }
  }

  // Settings page lists
  &--settings .list__item { 
    cursor: default; 
    &:hover { box-shadow: 0 0 0 0; } // remove shadow from list items on hover
  }

}