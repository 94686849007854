.tab-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0 10px 0;
  width: calc(100% + 30px);
  margin-left: -15px;  
  border-bottom: 1px solid #222;
  position: relative;

  span{
    font-size: 14px;
    line-height: 1.3;
  }

  &__item {
      color: #a5b8ca;
      margin: 0 10px;
      padding: 3px 10px;
      border-radius: 3px;
      transition: 0.1s ease;
      cursor: pointer;
      text-transform: uppercase;
      transition: 0.5s ease;
  
      &:hover {
          background-color: #2f404a;
          color: #fff;
      }

      &.active { 
        background-color: #2f404a;
        color: #fff;
    }
  }

  // Components / Microservices lists tab menu
  &--big {
    justify-content: flex-start;
    border-bottom: none;
    margin-left: -45px;
    padding: 0;

    .tab-menu__item {
      position: relative;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      padding: 15px 30px 25px;
      text-transform: capitalize;
      
      &:hover,
      &.active { background: none; }
      
      &.active::before {
        content: "";
        position: absolute;
        bottom: 3px;
        left:   0;
        right:  0;
        height: 4px;
        background-color: $blue;
        transition: $transition;
        opacity: 1;
      }

      // &.active::before { opacity: 1; }

      // &:hover::before { opacity: 1; }
    }
  }

  // Namespaces tabs
  &--namespaces {
    justify-content: flex-start;
    border-bottom: none;
    margin-left: -45px;
    padding: 0;

    .tab-menu__item {
      position: relative;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      padding: 15px 30px 25px;
      text-transform: none;
      &:hover,
      &.active { background: none; }
      
      &.active::before {
        content: "";
        position: absolute;
        bottom: 3px;
        left:   0;
        right:  0;
        height: 4px;
        background-color: $blue;
        transition: $transition;
        opacity: 1;
      }
    }
  }

  // Create Stage Deployment tab menu
  &--deployment {
    justify-content: flex-start;
    background-color: #2f404a;
    // max-width: 300px;
    // width: 100%;
    text-align: center;
    min-height: 64px;
    width: 100%;
    margin-left: 0;

    .tab-menu__item {
      position: relative;
      text-transform: capitalize;
      padding: 17px 20px;
      
      font-weight: bold;
      margin: 0;

      &.active::before {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        height: 4px;
        background-color: $blue;
        transition: $transition;
        opacity: 0;
      }

      &.active::before { opacity: 1; }

      &:hover::before  { opacity: 1; }
    }
  }
}

// Settings page tabs
.settings-tab {
  position: relative;
  cursor: pointer;
  min-height: 60px;
  height: 100%;
  padding: 0 10px 0 0 !important;

  @media (max-width: 1500px) { min-height: 65px; }

  &::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left:   0;
    width: 100%;
    height: 0px;
    transition: 0.2s ease;
    background-color: $blue;
  }

  &.active::before { height: 4px; }
}