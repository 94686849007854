.donut {
  padding: 5px 0;
  margin-top: 30px;

  h3 { 
      font-size: 18px;
      text-align: center;
  }

  &__text {
      font-family: "Roboto";
      fill: #fff;
      font-weight: bold;
      -moz-transform: translateY(0.25em);
      -ms-transform: translateY(0.25em);
      -webkit-transform: translateY(0.25em);
      transform: translateY(0.25em);

      &--build {
          translateY: 50px !important;
      }
  }

  &__number {
      font-size: 5px !important;
      line-height: 1;
      text-anchor: middle;
      -moz-transform: translateY(-0.25em);
      -ms-transform: translateY(-0.25em);
      -webkit-transform: translateY(-0.25em);
      transform: translateY(-0.25em);
  }

  &__ring,
  &__segment { transition: 0.2s ease /*0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)*/ }
}