.progressbar {
    margin: 15px 0;
  
    &__text { 
      margin-bottom: 5px;
      font-size: 12px;
    }
  
    &__bar {
      width: 100%;
      height: 7px;
      border-radius: 3.5px;
      background-color: $red;
      position: relative;
  
      & span {
        max-width: 100%;
        height: 100%;
        background-color: #46aa55;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 3.5px;
        transition: 0.5s ease;
      }
    }
  }