.table {
  width: calc(100% + 30px);
  margin: 20px -15px 0 -15px;
  color: $txt-white;

  // TABLE ICONS
  &__icon {
    display: inline-block;
    width:   8px;
    height: 11px;
    background-repeat:       no-repeat;
    background-position: center center;
    margin-left: 15px;
    cursor: pointer;
    
    &--sorting    { background-image: url('../../static/table--sort.png')       }
    &--descending { background-image: url('../../static/table--descending.png') }
    &--ascending  { background-image: url('../../static/table--ascending.png')  }
  }

  // Table head
  & thead { 
    background-color: #2f404a;
    text-align: center;
  }

  // Table headers
  & th { 
    padding: 8px 30px; 
    text-transform: uppercase;
    vertical-align: middle; 

    // text-align: left;
    // &:first-of-type { text-align: left;  }
    // &:last-of-type  { text-align: right; }
    
    & span {
      font-size: 12px;
      font-weight: 700;
      color: #abbed0;
    }
  }

  & td {
    line-height: 1;
    vertical-align: middle;
    font-size: 14px;

    small {
      color: #a0b3c5;
      font-size: 80%;
    }

    & > .circle {
      position: absolute;
      top:   17px;
      right: 58px;
    }

    .checkbox {
      margin:0;
      .checkbox__title {
        font-size: 14px;
      }
    }
  }

  // Table body data items
  & tbody td { 
    text-align: center; 
    padding: 15px 30px; 
  }
  & tbody td > * { margin: 0 auto; }
  & tbody td:first-of-type { text-align: left;}
  // & tbody td:last-of-type  { text-align: right; }

  & tbody tr td {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.2;
      background-color: #b8b8b8;

      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:first-child::after {
      right: 0;
      left: inherit;
      width: calc(100% - 30px);
    }

    &:last-child::after { width: calc(100% - 30px); }
    
  }

  & tbody tr:last-child td {
    &::after { display: none; }
  }

  &.pipelines {
    * { word-break: initial;  }

    & td:nth-child(2) {
      width: 200px; 
      padding: 17px 10px !important;
      word-break: break-all;
    }

    & td .circle--sm { 
      width: 12px !important;
      height: 12px !important;
    }
  }
  &.billing {
    width: 100%;
  } 


  // Microservice logs and events table
  &.pods-table {
    tr td { max-width: 300px; }
  }

  &.deployment-insights{

    thead > tr > th > span{ 
      color: white !important;
    }

    .badge {
      width: 120px !important;
      height: 25px;
    }

    .versions-wrapper{
      display: flex;
      width: fit-content;

      label{
        font-size: 20px;
        font-weight: bold;
        margin-right: 3px;
      }
    }

    tbody > tr > td:last-child {
        div{
          @media (max-width: 1800px) {
            max-width: 350px;
          }

          max-width: 600px;
        }
      }
    }

  .danger-icon{
    height: 16px;
    color: $red;
  }

  &.stage-details {
    margin-top: 0;
    
    & > thead th:first-of-type {
      width: 210px;
    }
  }

  .badge {
    margin: auto;
  }

  .pipeline-cell {
    text-align: left;
    a {
      color: #017dc4;
    }

    &__title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &__subtitle {
      color: #a5b8ca;
    }
  }

  .pipeline-commit-cell {
    text-align: left;
    float: left;
    max-width: 500px;

    &__title {
      font-weight: bold;
      margin-bottom: 5px;
      line-height: 15px;
    }


    &__subtitle {
      margin-bottom: 5px;
      line-height: 15px;
      color: #a5b8ca;

      a {
        color: #017dc4;
      }
    }

    &__profile {
      img {
        width: 15px;
        border-radius: 50%;
        margin-bottom: -3px;
        margin-right: 3px;
      }
    }
  }

  .pipeline-report-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 15px;
      bottom: -4px;
      margin-right: 10px;
    }
  }

  .pipeline-execution-cell {
    a {
      color:#017dc4;
    }
  }

  .pipeline-time-cell {
    text-align: right;

    &__title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    &__subtitle {
      color: #a5b8ca;
    }
  }
}

.pipeline-commit-cell {
  text-align: left;

  &__title {
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 15px;
  }


  &__subtitle {
    margin-bottom: 5px;
    line-height: 15px;
    color: #a5b8ca;

    a {
      color: #017dc4;
    }
  }

  &__profile {
    img {
      width: 15px;
      border-radius: 50%;
      margin-bottom: -3px;
      margin-right: 3px;
    }
  }
}
