// Table Status Badges
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size:  10px;
  border-radius: 3px;
  text-transform: uppercase;
  color: $txt-white;
  width:     70px;
  height:    20px;
  
  &--success  { background-color: $status-done;     }
  &--warning { background-color: $status-progress; }
  &--queued  { background-color: #606060; }
  &--fail     { background-color: $status-fail;     }
  &--aborted {background-color: #EE7C03}
  &--nobuild  {background-color: #3c515d; }
}