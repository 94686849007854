// TOGGLE SLIDER 
.toggle {
  display: flex; 
  margin-bottom: 5px;
   
  &__name {
    font-size: 14px;
    color:  #abbed0;
    margin-right: 10px;
    font-weight: bold;
  }

  &__text {
    color: #fff;
    padding-left: 6px;

    &.billing {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
  
  &__btn {
      position: relative;
      display: inline-block;
      width:  40px;
      height: 18px;
  
      & > input {
          opacity: 0;
          width: 0;
          height: 0;
      }

      &.billing {
        height: 21px;
      }
          
    & > input:checked ~ .toggle__slider-bg { opacity: 1; }
    & > input:checked ~ .toggle__circle { left: 24px; }
  }
  
  &__slider {
    position: absolute;
    cursor: pointer;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 25px;
  
    &.billing {
      top:3px;
      height: 21px;
      background-color: #69B55C;
    }

    // Toggle slider background
    &-bg {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(246deg, #3a7bd5, #00d2ff);
        border-radius: 25px;
        cursor: pointer;
        opacity: 0;
        transition: 0.6s ease;

        &.billing {
          top:3px;
          background-image: linear-gradient(246deg, #5bac69, #4abb59,);
        }
    }
  }

  // Toggle circle
  &__circle {
    position: absolute;
    width:  14px;
    height: 14px;
    border-radius: 50%;
    left:   3px;
    bottom: 2px;
    background-color: #fff;
    z-index: 3;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    cursor: pointer;

    &.billing {
      width:  24px;
      height: 24px;
      bottom: -2px;
      left: -6px;
      top: 1px;
    }

    // Toggle inner circle
    &::before {
        content: "";
        position: absolute;
        top:  50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:  4px;
        height: 4px;
        border: 1px solid #bcc3cf;
        border-radius: 50%;
    }
  }

  &.billing {
    font-size: 1.2em;
  }
}