// Search Bar input field
.searchbar {
  &-wrapper {
      width: 100%;
      .input__field {
          margin-bottom: 0;
          position: relative;
  
          &::placeholder {
              opacity: 0.5;
              font-size: 14px;
              font-style: italic;
              color: #abbed0;
          }
  
          &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 50px;
              width: 50px;
              background-color: red;
          }
      }

      // Dropdown Container menu at places where there are SearchBar and DropdownContainer together
      & ~ .dropdown {
          height: 50px;

          & .dropdown__input { height: 50px !important; }

          & .dropdown__btn { height: 100%; }
      }

      & .list-components .dropdown{
          max-width: 100%;

          &__btn {
              height: 50px;

              & .dropdown__input {
                  height: 50px !important;
              }
          }
      }
  }

  &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 10px 10px 10px;
      width: 100%;
  }

  &-wrapper.list-components {
    width: 100%;

    .searchbar__container {
        flex: 0 0 75%;
        max-width: 75%;

        & + div {
            flex: 0 0 25%;
            max-width: 25%;
            padding-right: 10px;
        }
    }
  }

  &__icon {
      color: #abbed0;
      max-width: 50px;
      height: 50px;
      width: 100%;
      border-radius: 4px;
      background-color: #16242c;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
  }

  &_padding {
      padding: 10px;
  }
}