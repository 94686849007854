.dropdown {
  max-width: 200px;
  width: 100%;
  height: 40px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  position: relative;
  margin-bottom: 4px;

  &__maintext {
    color: #e4e4e4;
  }

  &__btn {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--disabled * {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px !important;
    text-indent: 0 !important;
    font-size: 16px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $dropdown-bg;
    padding: 0 20px;

    &::placeholder {
      text-indent: 0 !important;
      font-size: 14px;
      opacity: 0.8;
    }
  }

  &__arrow {
    display: inline-block;
    height: 100%;
    width: 50px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $dropdown-bg;
    margin-left: 2px;

    &::before {
      content: url('../../static/dropdown-arrow.svg');
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-bottom: 5px;
    }
  }

  &__content {
    position: relative;
    z-index: 11;
    top: 0;
    // max-height: 160px;
    // overflow-y: auto;
    width: 100%;
    margin: 3px 0px 0 0;
    background-color: #16242c;
    transform: scale(0);
    transform-origin: top;
    opacity: 0;
    transition: $transition;
  }

  &__title {
    margin: 10px;
    text-align: left;
    color: white;
    padding-left: 0px !important;

    &::before {
      background-image: none !important;
    }
  }

  &__item {
    display: block;
    padding: 12px 10px;
    width: 100%;
    height: 100%;
    transition: $transition;
    margin-bottom: 0px;
    font-size: 14px;
    word-break: break-all;
    cursor: pointer;

    .dropdown__subtitle {
      color: #717e8a;
      font-size: 12px;

      .maintext {
        color: #e4e4e4;
        font-size: 14px;
      }

      .subtext {
        margin: 3px 0px 7px 0;
        color: #b1acac;
        font-size: 14px;
      }

      .shortened-content {
        margin: 12px 5px;
        color: #e4e4e4;
        font-size: 14px;

        & .faded {
          color: #b1acac;
          float: right;
        }
      }

      .link {
        color: #017dc4;
      }

      img {
        border-radius: 50%;
        margin-bottom: -3px;
        margin-right: 3px;
        width: 15px;
      }
    }

    .dropdown__sidetext {
      float: right;
      padding-top: 7px;

      small {
        color: #0075be;
        font-weight: bolder;
        font-size: 12px;
      }

    }

    &--emphasized {
      background-color: $blue50 !important;
      color: #fff;
    }

    &--separator {
      border-top: solid 1px #24333b;
      padding: 0;
    }

    &:hover,
    &.selected {
      color: $txt-white;

      small,
      span {
        color: #e4e4e4 !important;
      }
    }

    &:hover {
      background-color: #2f424c;
    }

    &.selected {
      background-color: $blue;
    }

  }

  // DROPDOWN MENU (Menu with 3 circle icons)
  &__menu {
    position: relative;

    &__btn {
      display: inline-block;
      width: 36px;
      height: 36px;


      background-image: url('../../static/menu-dots.svg');
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 3px;

      &:hover {
        background-color: #2f404a;
      }

      &--account {
        background-image: url('../../static/add-icon.svg');
      }

      &--play {
        background-image: url('../../static/play-icon.svg');
      }

      &--busy {
        background-image: url('../../static/busy-indicator.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    // &.open &__btn--account {
    //   transform: scale(1.1) rotate(45deg);
    // }

    .dropdown__content {
      position: absolute;
      top: 125%;
      overflow-y: visible;
      right: -10px;
      z-index: 100;
      border-radius: 4px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      background-color: #16242c;
      min-width: 220px;

      &::before {
        content: "";
        position: absolute;
        right: 20px;
        top: -5px;
        width: 14px;
        height: 14px;
        background-color: #16242c;
        transform: rotate(45deg);
        z-index: -1;
        display: block;
      }
      .items-container {
        max-height: 250px;
        overflow-y: scroll;
        margin: 0 !important;
      }
    }

    .dropdown__item {
      padding: 10px;
      margin: 0;
      text-align: left;

      &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:hover {
        background-color: #2e414a;

        & svg {
          color: $txt-white;
        }
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }


  &.open &__content,
  &__menu.open &__content {
    transform: scale(1);
    opacity: 1;
  }

  // Icons
  &__icon {
    max-width: 20px;
    width: 100%;
    margin-right: 15px;

    & svg {
      width: 16px;
      vertical-align: middle;
    }
  }

  // Custom scrollbar color
  & .thumb-vertical {
    background-color: $blue !important;
  }
}

// Stages page - card parent elements
.card--stages .dropdown__menu {
  margin-left: 10px;
  margin-top: 3px;
}


// Animation
@keyframes openAvatarDropdown {
  from {
    opacity: 0;
    transform: scale(0)
  }

  to {
    opacity: 1;
    transform: scale(1)
  }
}