.commit{
    max-width: 400px;
    text-align: left;
    
    .message{
        font-weight: bold;
        padding-bottom: 5px;

        &.stage-details-header {
            padding-bottom: 0;
        }
    }

    a{
        color: #007dc5; 
        &:hover { text-decoration: underline; }
    }

    label{
        color: #a5b8ca;
    }

    img{
        border-radius: 50%;
        margin-bottom: -3px;
        margin-right: 3px;
        width: 15px;
    }
}