.footer {
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid #333f46;

  &__menu__item {
      margin: 0 5px;
      font-size: 13px;
      line-height: 1.3;
      transition: .3s ease-in-out;

    &:hover { color: #fff; }

  }

  &__copyright { 
    font-size: 13px;
    margin: 0px 25px;
  }
}
