.checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  cursor: pointer;

  // Checkbox label container
  &__label {
    display: block;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top:  5px;
      left: 0;
      width:  18px;
      height: 18px;
      background-color: #16242c;
      transition: 0.1s ease;
      border-radius: 3px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      opacity: 0;
      left: 6px;
      top:  9px;
      width:  5px;
      height: 9px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

  }

  // Checkbox input
  &__input {
    position: absolute;
    top: 5px;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    width:  18px;
    z-index: 10;
  }

  // Checkbox 
  &__input:hover   ~ &__label::before { background-color: #4aa04b;  }
  &__input:checked ~ &__label::before { background-color: #4aa04b;  }

  // Checkmark Icon
  &__input:hover ~ &__label::after { 
    opacity: 1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &__input:checked ~ &__label::after { 
    opacity: 1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  // Checkbox content (title/description)
  &__content {
    margin-left: 30px;
    font-family: "Lato";
  }

  &__title { 
    font-size: 16px;
    margin-top: 4px;
  }
  &__description { 
    margin: 0 !important;
    font-size: 12px !important;
  }
}