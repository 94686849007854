// React transition group animations
.fade-enter {
  opacity: 0;
  transform: translateY(50px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 500ms ease-in;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(50px);
  transition: 500ms ease-in;
}

// FADE IN / OUT ANIMATION
.fade-in {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeIn 1s ease forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 1s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(50px);
  }
}

// SLIDE ANIMATION
.slide-in {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 0.8s cubic-bezier(0.49, -0.9, 0.69, 1.47) forwards;
}

.slide-out {
  opacity: 1;
  transform: translateX(0);
  animation: slideOut 0.85s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

// SCALE ANIMATION
.scale-in {
  opacity: 0;
  transform: scale(0);
  animation: scaleIn 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards
}
.scale-out {
  opacity: 1;
  transform: scale(1);
  animation: scaleOut 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards
}

@keyframes scaleIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleOut {
  to {
    opacity: 0;
    transform: scale(0);
  }
}

// MODALS ANIMATION
.animation-modal {
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 11 !important;
}
.modal-in .modal {
  opacity: 0;
  transform: translate(-50%, -45%);
  animation: modalIn 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards  
}
.modal {
  opacity: 1 !important;
  transform: translateY(-100%);
  animation: modalOut 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards  
}

@keyframes modalIn {
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
@keyframes modalOut {
  to {
    opacity: 0;
    transform: translateY(-90%);
  }
}
