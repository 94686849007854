.avatar {
  width:  38px;
  height: 38px;
  margin: 10px auto;
  display: block;
  position: relative;
  cursor: pointer;

  &__md {
    width: 90px;
    height: 90px;
    .sb-avatar__text{
        border: 5px solid #0e1f28;
    }
  }

  // New project avatar
  &--project {
    background-color: #2f404a;
    opacity: 0.3;
    border: dashed 1px #4c5a6f;
    padding-bottom: 2px;

    &:hover { opacity: 0.8;}
}   

  &.notification--show::after {
      content: "";
      position: absolute;
      top:   -3px;
      right: -5px;
      min-width:  14px;
      min-height: 14px;
      border-radius: 50%;
      border: 2px solid #101b21;
      background-color: $red;
  }

  // Avatar menu
  &__menu {
      display: block;
      position: absolute;
      top: -270px;
      left: 13px;
      background-color: #2f404a;
      width: 250px;
      border-radius: 3px;
      z-index: 0;
      opacity: 0;
      transform: scale(0);
      box-shadow: 0 0 10px rgba(0,0,0, 0.5);

      // When activated
      &--open {
          transition: 0.5s;
          opacity: 1;
          transform: scale(1);
          animation: openAvatarDropdown 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
      }

      &::before {
          content: '';
          position: absolute;
          bottom: -9px;
          left:    13px;
          width:   16px;
          height:  16px;
          background-color: #2f404a;
          transform: rotate(45deg);
          z-index: -1;
      }
  }

  // Avatar menu header
  &__header {
      background-color: #24323b;
      border-bottom: 1px solid #16242c;
      padding: 12px 0 10px 25px;
      border-top-right-radius: 3px;
      border-top-left-radius:  3px;
  }

  // Avatar menu list
  &__list { padding: 10px 0 15px 0; }

  &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      transition: 0.2s ease;
      margin: 0;
      padding: 8px 0 8px 20px;

      svg { 
          margin-right: 15px;
          width:  15px;
          height: 15px;
      }

      &:hover {
          color: #fff;
          
          svg g,
          svg path { stroke: #fff; }
      }
  }
}

@keyframes openAvatarDropdown {
  from {
      opacity: 0;
      transform: scale(0);
  } to {
      opacity: 1;
      transform: scale(1);
  }
}