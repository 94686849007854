// TOASTIFY NOTIFICATIONS
.Toastify {
    &__toast {
        position: relative;
        display: flex !important;
        align-items: center !important;
        padding-left: 35px !important;
        border-radius: 3px !important;
        min-height: 45px !important;
        &::before {
            position: absolute;
            left: 10px;
            top: 14px;
            width: 20px;
            height: 20px;
        }
        // Container
        &-container {
            position: fixed !important;
            top: 5px !important;
            right: 5px !important;
            margin: 0 !important;
            width: calc(30% + 10px) !important;
            transition: 0.3s ease;
            white-space: pre-wrap;
        }
        // Body
        &-body {
            display: flex !important;
            align-items: center !important;
            font-size: 14px;
            font-weight: bold;
        }
        // States
        &--success {
            background: $green !important;
            &::before {
                content: url('../../static/succes-icon.svg');
            }
        }
        &--error {
            background: $red !important;
            &::before {
                content: url('../../static/danger-icon.svg');
            }
        }
        &--warning {
            background-color: $yellow;
            &::before {
                content: url('../../static/danger-icon.svg');
            }
        }
        &--info {
            background-color: #1f2e38 !important;
            &::before {
                content: url('../../static/info-icon.svg')
            }
            ;
        }
        & h4 {
            display: inline-block;
            font-size: 16px;
            margin-left: 10px;
        }
        &-container--bottom-center {
            top: calc(95% - 5px) !important;
            left: 0 !important;
            width: 100% !important;
            & .Toastify__toast--info::before {
                content: url('../../static/danger-icon.svg');
            }
        }
    }
    // Toastify close button
    &__close-button {
        padding-top: 5px !important;
        opacity: 1 !important;
    }
}