/*----------------------------------------------------------------------------*/
/*#GLOBAL STYLE*/
/*----------------------------------------------------------------------------*/

* {
  word-break: break-word;
}

body {
  background-color: $bg-grey;
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-weight: 500;
  line-height: 1.3;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 14px;
}

p,
a,
ul li,
ol li {
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 20px;
  color: #a5b8ca;
}

a {
  margin-bottom: 0;
}

// CUSTOM SCROLL BARS STYLE
.track {
  &-vertical {
    top: 15px !important;
    right: 0 !important;
    height: 100% !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: #16242c;

    // Menubar
    &--menubar {
      right: 0px !important;
    }

    // Overview stages list
    &--stages {
      top: 8px !important;
    }

    // Stage resources list - review section
    &--review {
      top: 3px !important;
      right: 3px !important;
    }

    &--body {
      right: 2px !important;
    }

    // Deployment menu
    &--deployment-menu {
      right: 8px !important;
    }

    &--components {
      min-height: calc(100vh - 390px);
      max-height: calc(100vh - 350px);
    }
  }
}
.thumb {
  &-vertical {
    height: 100%;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #2f404a;
  }
}
.reset--top {
  top: 0 !important;
}

.sideMenu{
  & > div{
    max-height: calc(100% + 7px) !important;
  }
}
// PAGE OVERLAY
.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;

  &--build {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

// ASIDE MENU (sidebar + menubar)
.aside {
  min-width: 80px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 11;

  &.shown {
    width: 60px;
  }
}

// NOTIFICATIONS
.notification {
  position: relative;

  &::before {
    content: attr(data-notification);
    position: absolute;
    top: -4px;
    left: 51%;
    display: none;
    justify-content: center;
    align-items: center;
    min-width: 12px;
    min-height: 12px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 50%;
    background-color: red;
    color: #fff;
  }

  &--show {
    &::before {
      display: flex;
    }
  }
}

// Configure icon (cogwheel)
.configure-icon {
  width: 20px;
  cursor: pointer;

  & path {
    fill: transparent;
    transition: $transition;
    stroke: #abbed0;
    stroke-width: 30px;
  }

  &:hover path {
    fill: #abbed0;
  }
  &--active path {
    fill: #abbed0;
  }
}

// Users icons
.users {
  display: flex;
}

// LOGIN/REGISTER pages container's footer
.welcome {
  h1 {
    font-size: 30px;
  }

  .footer {
    width: 60%;
    margin: 0 auto;
    align-items: center;

    @media (max-width: 991px) {
      width: 100%;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      padding-bottom: 30px;

      p {
        margin-bottom: 20px;
      }
    }
  }

  // Microtica Big logo
  .microtica-big-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.15;
  }

  .microtica-text-logo {
    width: 250px;

    @media (max-width: 575px) {
      margin-bottom: 0 !important;
    }
  }

  .appSumo-text-logo {
    width: 150px;
  }

  .logos-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 420px) {
    .user-remember {
      justify-content: center;
      flex-direction: column;

      .checkbox {
        margin-bottom: 15px;
      }
    }

    .btn--xl {
      max-width: 100px;
      height: 40px;
    }
  }

  @media (max-width: 575px) {
    padding-right: 0;
    padding-left: 0;

    .container--md {
      min-height: calc(100vh - 95px);
    }

    .box--padding-lg {
      padding: 30px 20px !important;
    }

    .input--login {
      input {
        height: 40px;
      }

      .input__field--error ~ svg {
        top: 14px;
        right: -16px;
        width: 14px;
        height: 14px;
      }
    }

    h5 {
      font-size: 16px;
      margin-bottom: 15px !important;
    }

    .microtica-big-logo {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .btn--xl {
      max-width: 250px;
      height: 45px;
      font-size: 16px;
    }

    .txt--md {
      font-size: 16px;

      a {
        font-size: 16px;
      }
    }
  }
}

// TABLE WRAPPER
.table-wrapper {
  overflow-y: auto;
  overflow-x: hidden; // ?
  width: calc(100% + 45px);
  margin: 0 -15px;

  &--sm {
    max-height: 340px;
  }

  & > table {
    width: 100%;
    margin: 20px -15px 0 0;

    // Increase table data element padding size if its inside a table-wrapper
    & tbody td {
      padding: 17px 30px;
    }
  }

  &-sticky{
    max-height: 1000px; 
    width: calc(100% + 45px);
    margin: 0 -15px;
    th{
      position: sticky;
      top: 0;
      background-color: #2f404a;
      z-index: 10;
    }
    &_dark{
        width: calc(100% + 30px);
      th{
        background-color: #1f2e38;
      }
    }
      table {
      width: 100%;
      margin: 20px -15px 0 0px;
    }
  }
}

// CREATE TIMELINE (Component, Microservice, Stages etc.)
.steps-wrapper {
  height: 80px;
  position: relative;
}

// PROJECTS PAGE
.projects {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    & p {
      font-size: 16px;
      color: $txt-white;
    }

    & svg {
      width: 14px;
      height: 14px;
      transition: $transition;
      margin-right: 10px;
      cursor: pointer;

      &:hover path {
        fill: #abbed0;
      }
    }
  }
}

// Connect AWS / Git images
.connect-account {
  max-height: 90px;
  height: 100%;
  margin-bottom: 30px;

  & svg {
    height: 100%;
  }
}

// Review section from StageDetails page
.review {
  width: 100%;
  max-height: calc(100vh - 200px);

  &__text {
    max-width: 450px;
    margin: 0;
    font-size: 20px;
    color: $txt-white;
    text-align: center;
  }

  & .card--stages {
    padding: 15px 25px;

    & .stages {
      padding: 5px 0;
    }
  }

  & .card {
    margin-top: 10px !important;
  }

  & .kubernetes-link {
    font-size: inherit;
  }
}

.deployment {
  &-menu {
    max-width: 400px;
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 10px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #16242c;
      z-index: 10;
    }

    // SEARCH BAR
    .searchbar__container {
      padding: 15px 10px 5px 10px;

      & .searchbar__icon {
        height: 36px;
      }

      & .input__field {
        margin: 0;
        height: 36px;
      }
    }

    // LIST
    .list__container {
      padding: 10px 10px 30px 0;
    }

    .list {
      max-height: calc(100vh - 370px);
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 20px;

      &__item:hover .circle {
        background-color: $green;
      }

      &__text {
        h5 {
          font-size: 16px;
          font-weight: bold;
        }
        p {
          font-size: 12px;
          text-transform: none;
        }
      }

      &__icons {
        .circle {
          font-size: 14px;
          font-weight: bold;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  // Clusters page deployment modal progress
  &__bar {
    width: 100px;
    height: 32px;
    border: 8px solid #3f3d56;
    display: block;
    border-radius: 20px;
    position: absolute;
    left: 170px;
    top: 40px;
    z-index: 10;
    overflow: hidden;

    & svg {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 16px;
      height: 16px;

      & path {
        fill: #fff;
      }
    }
  }

  &__progress {
    max-width: 100%;
    height: 100%;
    background-color: #007dc5;
    border-radius: 20px;
    transition: 0.5s ease;
  }
}

// 404 Page Not Found & projects screen when there are no projects
.page-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
  padding-bottom: 100px;

  h1,
  h3 {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 35px;
    max-width: 50%;
    color: $txt-white;
    font-size: 20px;
    text-align: center;
    line-height: 25px;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
  }

  & .btn {
    max-width: 350px;
    min-height: 55px;
    background-color: #48a14a;
    color: #eee;
  }

  & img {
    margin-bottom: 25px;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: 50%;
  }

  &--project {
    height: 100%;

    p {
      font-size: 16px;
      margin-bottom: 40px;
    }

    a {
      font-size: inherit;
      color: #4a90e2;
    }
  }

  .feature-box {
    max-width: 730px;
    display: flex;
    flex-direction: column;
    background-color: #24323b;
    padding: 25px;
    color: white;
    box-shadow: 0 5px 10px -10px rgba(0,0,0,.2), 0 2px 4px 0 rgba(0,0,0,.5);
    border-radius: 5px;
    margin-bottom: 20px;

    &__subtitle {
      color: #a5b8ca;
      text-align: left;
      max-width: inherit;
      margin: 0;
    }
  }

  @media (max-width: 1400px) {
    padding-bottom: 30px;
  }
}

// DeployMicroserviceModal - StageCluster - scalling
.scalling {
  &__title {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: normal;
  }

  &__item {
    margin-bottom: 17px;

    h5 {
      margin-bottom: 10px;
      font-size: 16px;
    }
    small {
      color: #a5b8ca;
    }
  }

  &__info {
    margin-bottom: 5px;

    svg {
      max-width: 25px;
      width: 100%;
      height: 25px;
      margin-right: 5px;
      color: #abbed0;
    }
    h5 {
      margin-bottom: 10px;
    }
    span {
      color: #abbed0;
      vertical-align: top;
      font-size: 18px;
    }
    span .value {
      color: #67be68;
    }
  }

  &__input {
    position: relative;
    margin-bottom: 20px;

    h5 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    & .input .input__field {
      width: 157px;
      height: 48px;
      padding-right: 10px;
      margin-bottom: 0;
    }

    & svg {
      position: absolute;
      right: -28px;
      top: 38px;
      width: 16px;
      height: 16px;
      cursor: pointer;

      & path {
        fill: #abbed0;
      }
    }
  }

  &__slider {
    position: relative;

    // Slider input
    & input {
      width: 100%;
      height: 8px;
      background-color: #67be68;
      -webkit-appearance: none;
      outline: none;
      border-radius: 10px;
      cursor: pointer;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        cursor: grab;
      }
    }

    // SVG Info icon
    & svg {
      position: absolute;
      top: 3px;
      right: -30px;
      width: 16px;
      height: 16px;
      cursor: pointer;

      & path {
        fill: #abbed0;
      }
    }
  }

  &__error {
    position: absolute;
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
}

// CREATE COMPONENT/CREATE MICROSERVICE ENVIRONMENT VARIABLES
.variables {
  margin-bottom: 10px;

  &__item {
    position: relative;

    svg {
      position: absolute;
      top: 16px;
      right: -22px;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }

    & .toggle {
      display: flex;
      align-items: flex-start;
      margin: 0;

      &__btn {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }
}

// Pie charts
.chartjs-render-monitor {
  max-width: 180px;
  max-height: 180px;
  pointer-events: none;
}

// Progress icon stages list, build details, deployment plan
.progress-icon {
  transform-origin: center;
  animation: inProgress 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards
    infinite;
}

// Settings page - Git accounts modal accounts separator
.separator {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -3px;
    transform: translateY(-50%);
    height: 90%;
    opacity: 0.2;
    width: 1px;
    border: solid 1px #979797;
  }
}

// Login page Remember me checkbox / Forgot password section
.user-remember {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;

  & .checkbox {
    margin: 0;
    &__title {
      font-size: 14px;
    }

    &__label::before {
      top: 0;
    }
    &__label::after {
      top: 4px;
    }
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// Mail confirmed - Signup page
.mail-confirmed {
  h1 {
    font-size: 36px;
  }
  .btn--lg {
    max-width: 350px;
    min-height: 54px;
    font-size: 18px;
  }
}

// Mark element as Kubernetes
.kubernetes { border-bottom: 4px solid #007dc5 !important; }
.succeeded-status { border-bottom: 4px solid #67be68 !important; }
.failed-status { border-bottom: 4px solid #f84737 !important; }
.running-status { border-bottom: 4px solid #f5a623 !important; }
.aborted-status { border-bottom: 4px solid #EE7C03 !important; }

.overview {
  .dropdown__input {
    height: 41px !important;
  }
  .dropdown__btn {
    height: 41px;
  }
}

// always show horizontal scrollbar (in pipeline graph)
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    height:  10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 13px;
    background-color:#24323d;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

@keyframes inProgress {
  0% {
    transform: translateX(0px) scale(1) rotate(0deg);
  }
  50% {
    transform: translateX(-2px) scale(0.85) rotate(180deg);
  }
  100% {
    transform: translateX(0px) scale(1) rotate(360deg);
  }
}

// Go back button / text
.back {
  position: relative;

  &::before {
    content: url("../../static/arrow icons.svg");
    position: absolute;
    top: -1px;
    left: -10px;
    display: block;
    transform: rotate(90deg);
    opacity: 0;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  &:hover::before {
    left: -15px;
    opacity: 1;
  }
}

// Build waiting process
.build-process {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 13;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    margin-bottom: 30px;
    animation: donutRotation 2s ease forwards infinite;
  }

  img {
    position: absolute;
    top: 30px;
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
  }
}

//Style yaml editor page
.yaml-editor{
  margin-top: 60px;
  border-bottom: 3px solid #16242c;
  border-right: 3px solid #16242c;
  border-left: 3px solid #16242c;
}
.yaml-editor-tabs{
  position:absolute;
  margin-top: 20px;
  height: 40px;
  background-color:#16242c;
}

.yaml-editor-tabs  h6 {
  display:inline-block;
  margin-top:10px;
}
.yaml-editor-tabs div {
  cursor:pointer
}
.yaml-editor-activeTab {
  background-color:#002B36
}
.yaml-editor-icon{
  width:15px;
  margin-top:14px;
  margin-right: 15px;
}
.snippet-copy{
  z-index: 1;
  position: absolute;
  right: 5%;
  margin-top: 35px !important;
  background: rgba(255, 255, 255, 0.1) ;

}
.snippet-copy-text{
  right:20%;
  position:absolute;
  margin-top:44px !important;
  z-index:1;
  font-size:14px;
}



// RESPONSIVE
@media (min-width: 1500px) {
  .row--projects [class*="col-xl"] {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (max-width: 1500px) {
  // Login / Register pages
  .welcome {
    h2 {
      font-size: 28px;
    }
    .box {
      max-width: 480px;
    }
    .box--padding-lg {
      padding: 40px;
    }
    // .txt--md { font-size: 18px; }
    // .txt--sm { font-size: 16px; }
  }
}

@media (max-height: 800px) {
  // Login / Register pages
  .container--padding {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .welcome {
    .box--padding-lg {
      padding: 30px 35px;
    }
  }
}
@media (max-width: 1199px) {
  .card {
    &--bottom {
      margin-bottom: 30px;
    }
  }

  // Create pages (Components, Microservices etc.)
  .page-content {
    & .card {
      margin-bottom: 30px;
    }
  }
}

@keyframes donutRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
