// CIRCLES (Menu Circles and Lists/Timeline Circles)
.circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: $txt-white;
  transition: $transition;
  
  &--xs {
      width:  12px;
      height: 12px;
  }

  &--sm { 
      width:  18px;
      height: 18px;
  }

  &--md {
      width:  22px;
      height: 22px;
  }

  &--lg {
      width:  34px;
      height: 34px;
  }

  &--xl {
      max-width: 118px;
      width: 100%;
      min-height: 118px;
  }

  &--grey { background-color: #24323b; }

  &--user { z-index: 3; }
  &--user:nth-of-type(2) { 
      z-index: 2;
      margin-left: -5px; 
  }
  &--user:nth-of-type(3) { 
      z-index: 1;
      margin-left: -5px; 
  }

  &--add {
      width:  18px;
      height: 18px;
      margin-left: 8px;
      background-color: #2f404a;
      font-weight: 400;
      font-size: 13px;
  }

  &__image { 
      max-width: 100%;
      border-radius: 50%;
  }

  &__number {
      font-size: 13px;
      font-weight: 700;
      font-family: "Lato";
  }

  // Pipelines table status circles
  &--table {
    position: absolute !important;
    top: 30px !important;
  }

  // Pipelines Build step
  &--step{
    width:  22px;
    height: 22px;
    svg{
        padding: 2px;
        margin: 2px;
    }
}

  // STATUS COLORS
  &--success { background-color: $green;  }
  &--fail    { background-color: $red;    }
  &--aborted { background-color: #EE7C03 }
  &--queued  { background-color: #606060; }
  &--warning { background-color: $yellow; }
  &--created { background-color: $blue;   }
  &--ready   { background-color: $green;  }
  &--white   { background-color: #fff;  }
  &--nobuild { background-color: #3c515d; }

  // Stages Status circles
  &--placeholder    { background-color: #24323b; }
  &--grey           { background-color: #2f404a; }
}