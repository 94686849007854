/*----------------------------------------------------------------------------
                                #SIDEMENU
---------------------------------------------------------------------------*/
.sidebar {
    min-height: 100vh;
    width: 80px;
    background-color: $bg-dark-grey;
    z-index: 11;
    min-width: 80px;

    &__logo {
        margin: 0 auto;
        padding: 20px;
        width: 100%;
    }

    &__top { 
        max-height: 80%;
        height: 100%;
    }

    &__bottom { margin-bottom: 30px; }

    &__icons {
        margin-bottom: 40px;
        
        // All child elements of the sidebar__icons element
        * { 
            display: block;
            margin: 25px auto;
        }
    }
}
