/*----------------------------------------------------------------------------
                                #CARDS
---------------------------------------------------------------------------*/
.card {
  width: 100%;
  min-height: calc((100vh / 2) - 123px);
  padding: 15px;
  background-color: $card-bg;
  border-radius: 5px;

  &--small {
    width: 100%;
    margin-top: 10px;
    min-height: 0px;
    padding: 0;
  }

  &__header-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &--lists {
      padding: 0 30px;
    }

    &--replicate {
      padding: 0 20px 10px 0;
      margin-bottom: 0;

      & .card__header {
        max-width: 250px;
        width: 100%;
        padding-top: 0;
      }

      & .card__title {
        font-size: 22px;
        font-weight: bold;
        display: inline-block;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      & .card__header__item {
        max-width: 250px;
      }

      &::before {
        width: calc(100% + 30px) !important;
        margin: 0 -15px !important;
      }

      &::after {
        display: none;
      }

      & .dropdown__btn {
        background-color: #2b3e49;

        &:hover {
          background-color: #2b3e49 !important;
        }
      }


      & .dropdown__menu {
        height: 36px;
      }
    }

    &--clusters {
      padding: 0 0 10px 10px;

      &::before {
        width: calc(100% + 40px) !important;
        margin: 0 -20px !important;
      }

      &::after {
        display: none;
      }
    }

    p {
      a {
        font-size: inherit;
        color: $lightBlue;
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: calc(100% + 30px);
      margin: 0 -15px;
      height: 1px;
      background-color: #16242c;
    }
  }

  // Card content
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 50px);
    margin: auto;
    padding: 25px 0;

    &__text {
      font-size: 18px;
      color: $txt-white;
    }

    // Override button style
    & .btn {
      max-width: 305px;
    }
  }

  &--vertical {
    height: 100%;
  }


  // Card component on Pipelines page
  &--pipelines {
    padding: 15px 30px 15px 15px;
  }

  &--billing {
    min-height: calc(100% - 220px);
  }

  // Card comopnent on Stages page
  &--stages {
    // min-height: 100%;
    padding: 8px 35px;

    &:first-of-type {
      margin-bottom: 30px;
    }

    & .card__title {
      font-weight: bold;
      font-size: 22px;

      a {
        color: lighten($color: #017dc4, $amount: 3);
      }
    }

    & .card__header::before {
      display: none;
    }

    & .stages {
      padding: 10px 0;
    }

    & .plan {
      padding: 10px 5px 0 5px !important;

      .stages__info-container {
        align-items: unset;
      }
    }

    & .stages__info-container .circle {
      margin-right: 10px;
    }

    & .stages__info--description {
      font-size: 14px;
      color: #a5b8ca;
      text-transform: none;
      text-align: left;
    }

    // Change font size of card title that is located inside of the parent Card stages component
    & .card .card__title {
      font-size: 16px;
    }

    // Card Header bottom line - bigger width & margin if its inside a card--stages element
    &>.card__header-wrapper::after {
      width: calc(100% + 80px);
      margin: 0 -40px;
    }

    &>.card {
      min-height: 100%;
      margin: 30px auto;

      // Light cards that are children of Card--stages components
      &.light {
        padding: 5px 15px;
      }

      &.pipeline {
        padding: 5px 5px;
      }
    }
  }

  // CARD COMPONENT ON REPLICATE / DEPLOYMENT PAGE
  &--replicate {
    padding: 0 15px 0 5px !important;

    &>.card {
      min-height: 100%;
      margin: 0 auto 10px auto;
    }

    &-main {
      padding-bottom: 0;
    }

    &-main .deployment-menu {
      margin-right: -15px;
      min-height: calc(100vh - 300px);

      @media (max-height: 900px) {
        min-height: calc(100vh - 240px);
      }
    }

    &-main .review {
      height: calc(100vh - 255px);
      margin-right: 10px;

      @media (max-height: 900px) {
        height: calc(100vh - 240px);
      }
    }

    &.card--stages:first-of-type {
      margin: 0;
    }

    ;

  }


  // CARD COMPONENT ON PIPELINE BOX / DEPLOYMENT PAGE
  &--pipeline {
    padding: 0 0px 0 0px !important;

    &>.card {
      min-height: 100%;
      margin: 0 auto 10px auto;
    }

    &-main {
      padding-bottom: 0;
    }

    &-main .deployment-menu {
      margin-right: -15px;
      min-height: calc(100vh - 300px);

      @media (max-height: 900px) {
        min-height: calc(100vh - 240px);
      }
    }

    &-main .review {
      padding-top: 10px;
      height: calc(100vh - 320px);
      margin-right: 10px;

      @media (max-height: 900px) {
        height: calc(100vh - 240px);
      }
    }

    &.card--stages:first-of-type {
      margin: 0;
    }

    ;

    .step-reports {
      margin: 10px;
      color: #a5b8ca;
      font-size: 14px;
      display: flex;
      align-items: center;

      img {
        width: 15px;
        margin-right: 10px;
      }
    }

  }

  // Last card
  &--last {
    & .dropdown__menu .dropdown__content {
      top: -230%;

      &::before {
        top: initial;
        bottom: -8px;
      }
    }
  }

  // CARD HEADER
  &--underline {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% + 70px);
      margin: 0 -35px;
      height: 1px;
      background-color: #16242c;
      z-index: 10;
    }
  }

  &__header {
    padding: 10px;

    // display: flex;
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1000px;
      width: 70%;
    }

    &__item {
      width: 100%;
      padding-bottom: 10px;

      h5 {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }

  // Card content's padding (example- create microservice page)
  &__content-padding {
    padding-top: 32px;

    // Checkbox component inside a content-padding element
    &>.checkbox {
      margin-bottom: 20px;
    }

    & .input__field {
      margin: 0;
      height: 40px !important;
    }
  }

  // Card Build/Deployment details
  &--details {
    padding: 0;

    & .searchbar__container {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 15px;
    }

    & .input__field {
      margin-bottom: 0;
    }
  }

  &--build {
    min-height: 70vh ;

    & .table {
      width: 100%;
      margin: 20px 0 0 0;

      th {
        padding: 8px 25px;

        @media (max-width: 1450px) {
          padding: 8px 20px;
        }

        &:first-of-type,
        &:last-of-type {
          text-align: center;
        }
      }

      & tbody td:first-of-type,
      & tbody td:last-of-type {
        text-align: center;
      }

      & tbody td:last-of-type {
        max-width: 250px;
      }
    }

    .details__code-block {
      max-height: unset;
      min-height: 70vh;
      width: unset;
      margin: 0 30px 0 26px;
    }

    pre {
      max-width: 100%;
      white-space: break-spaces;
    }
  }

  // Kubernetes Clusters page
  &--clusters {

    // Clusters page container card holding the charts and the tables
    &-container {
      & .card__header__item {
        max-width: auto;
        width: auto;
        padding-right: 50px;
      }
    }

    min-height: 100%;
    margin: 30px 0;

    &-table {

      & table thead th,
      & table tbody td {
        min-width: 100px;
      }
    }
  }

  // Settings (Management page)
  &--settings {
    min-height: calc(100vh - 130px);

    // Child card elements
    @media (max-height: 900px) {
      & .card {
        height: 100%;
      }
    }

    .underline {
      position: absolute;
      background-color: #007dc5;
      transition: 0.5s ease;
      bottom: 2px;

      @media (max-width: 1800px) {
        bottom: 4px
      }

    }

  }

  // CARDS WITH DARK THEME
  &.dark {

    .dropdown__btn:hover {
      background-color: #24323b;
    }

    table {
      thead {
        background-color: #2f404a;
      }

      tbody {
        background-color: #24323b;
      }

      .dropdown__btn:hover {
        background-color: #2f404a
      }

      ;

    }

    // .dropdown__menu.open .dropdown__menu__btn { background-color: #2f404a; }

    // A Card with theme Light that is inside a parent Card with theme Dark
    &>.card.light {
      .dropdown__btn:hover {
        background-color: #1f2e38
      }

      ;

      .dropdown__menu.open .dropdown__menu__btn {
        background-color: #1f2e38;
      }
    }

    .list-box {
      .list-box-item {
        padding: 20px;
        background-color: #16252c;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        min-height: 130px;
        margin: 10px;
        align-items: center;
        border: solid #16252c 2px;

        &__selected {
          border: solid #017dc4 2px;
        }

        .list-box__title {
          color: white;
          margin-left: 15px;
        }

        .list-box__description {
          color: #a5b8ca;
          margin-left: 15px;
        }

        img {
          height: 35px;
        }

      }
    }
  }

  &--vertical.dark {
    .dropdown__menu.open .dropdown__menu__btn {
      background-color: #24323b;
    }
  }

  // CARDS WITH LIGHT THEME
  &.light {
    background-color: #2f404a;

    table {

      thead,
      .dropdown__btn:hover {
        background-color: #1f2e38;
      }

      tbody {
        background-color: #2f404a;
      }
    }

    .dropdown__btn:hover {
      background-color: #2f404a;
    }

    .dropdown__menu.open .dropdown__menu__btn {
      background-color: #2f404a;
    }

    .list__item {
      background-color: #24323b;
    }

    // A Card with theme Dark that is inside a parent Card with theme Light
    &>.card.dark {
      .dropdown__btn:hover {
        background-color: #2f404a
      }

      ;

      .dropdown__menu.open .dropdown__menu__btn {
        background-color: #2f404a;
      }
    }

    &.stage-details {
      padding: 5px 0;
      min-height: 60px;
      margin-bottom: 10px;

      & table th {
        text-transform: capitalize;
      }
    }
  }

  // CARDS FOR PIPELINE
  &.pipeline {
    background-color: #2f404a;

    table {

      thead,
      .dropdown__btn:hover {
        background-color: #1f2e38;
      }

      tbody {
        background-color: #2f404a;
      }
    }

    .dropdown__btn:hover {
      background-color: #2f404a;
    }

    .dropdown__menu.open .dropdown__menu__btn {
      background-color: #2f404a;
    }

    .list__item {
      background-color: #24323b;
    }

    // A Card with theme Dark that is inside a parent Card with theme Light
    &>.card.dark {
      .dropdown__btn:hover {
        background-color: #2f404a
      }

      ;

      .dropdown__menu.open .dropdown__menu__btn {
        background-color: #2f404a;
      }
    }
  }

  // Card vertical full screen
  &--vertical-full {
    min-height: calc(100vh - 330px);
  }


  // Media querries
  @media (max-width: 1199px) {

    // Card content's padding (example create microservices page)
    &__content-padding {
      padding-top: 0;
    }
  }

}

.card-shadow:hover {
  transition: 0.3s ease;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
}

.card-succeeded {
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  border-color: #67be68;
}

.card-running {
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  border-color: #f5a623;
}

.card-failed {
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  border-color: #f84737;
}

.card-aborted {
  border-top: 2px solid;
  border-right: 2px solid;
  border-left: 2px solid;
  border-color: #EE7C03;
}

// PROJECTS CARDS
.project-card {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 20px;

  width: 100%;
  background-color: #2f404a;
  border-radius: 4px;
  box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.18);
  cursor: pointer;

  &__logo {
    margin-right: 20px;

    & img {
      width: 100%;
      border-radius: 50%;
    }
  }

  // Project card header
  &__header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: calc(100% + 20px);
      height: 1px;
      background-color: #24323b;
    }
  }

  &__title {
    font-size: 16px;
  }

  &__content {
    width: 100%;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }

  &__description {
    max-width: 400px;
    width: 100%;
    margin: 0;
  }

  // Project's info
  &__info {
    display: flex;
    align-items: center;

    &>* {
      margin: 0 20px;
    }
  }

  // Dropdown menu background color on hover (default)
  .dropdown__btn:hover {
    background-color: #24323b;
  }

  .dropdown__menu.open .dropdown__menu__btn {
    background-color: #24323b;
  }
}

.flex {
  display: flex !important;
}

//Collapsible card
.collapsible-button {
  margin-bottom: 0px;
  float: left;
  display: inline;
  width: 220px;
  position: absolute;
  left: -15px;
  top: -5px;
  padding-bottom: 12px;
  height: calc(100% + 10px);
  cursor: pointer;
  background-color: #2f404a;
  border-radius: 5px 0px 0px 5px;

  span {
    justify-content: normal;
    width: 20%;
  }

  // STATUS COLORS
  &.deployed {
    background-color: #67be68;
  }

  &.success {
    background-color: $green;
  }

  &.fail {
    background-color: $red;
  }

  &.warning {
    background-color: $yellow;
  }
}

.collapsible-header {
  display: flex;
  width: 100%;
  align-items: center;

  a.txt--highlight {
    font-size: 16px;
  }

  .first-item {
    float: left;
    width: 100%;
    padding-top: 10px;
  }

  .elements {
    margin-left: 185px;
    display: flex;
    width: 100%;

    span {
      min-width: 250px;
      width: 250px;
      align-self: center;
      text-align: left;

      .d-inline-block {
        min-width: unset;
        width: unset;
      }

      &.logs-btn {
        min-width: 145px;
        width: 145px;

        a {
          float: right;
          min-width: 110px;
        }
      }

      &.field-1 {
        text-align: center;

        &.stage-details {
          width: 100%;

          span:first-child{
            display: grid;
            width: 100%;
            grid-template-columns: 150px auto;
            align-items: center;
          }

          &+div.vertical {
            border: none;
          }
        }
      }

      &.field-2 {
        width: 100%;

        &.stage-details {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          min-width: 150px;
          width: 150px;

          &>.dropdown__menu {
            padding-top: 5px;
          }
        }
      }

      &.stage-details-header {
        display: flex;
        align-items: center;
        width: auto;

        & .commit {
          max-width: 450px;
        }
      }
    }
  }
}

.vertical {
  border-left: 1px solid #16242c;
  height: 45px;
  margin: 3px 20px 3px 20px;
  align-self: center;

  &.disable {
    border: none;
  }
}

.collapsible-block {
  display: block;
  transition: max-height 0.5s ease;
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 700px;
  }

  &.commit {
    font-size: 17px;
  }
}

.collapsible-table {
  overflow-y: auto;
  max-height: 390px;
}

.service-insights-card {
  .card {
    min-height: 0px !important;
    padding: 5px 0px 5px 15px !important;
  }

  .card__header-wrapper::after {
    width: calc(100% + 15px);
  }
}