// STAGES Page cards
.stages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  &__description { 
      max-width: 430px;
      padding-left: 10px;
      margin: 0;
  }

  &__info-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 200px repeat(3, minmax(50px, 150px)) 300px;
    overflow: auto;
    // padding-left: 10px;

    @media (max-width: 1470px) {
      grid-template-columns: 200px 120px 110px 1fr
    }

    // Stages info wrapper for Stages List page
    &--list {
      padding-left: 10px;
      grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr 2fr !important;
    }

    .disable__link {
      pointer-events: none;
    }
    
    // Stages info warpper for Build details page
    &--details { grid-template-columns: repeat(5, 1fr) !important; }
    &--plan-details { grid-template-columns: repeat(4, 1fr) !important; }
  }

  //Status info wrapper component list in create plan
  .plan {
    grid-template-columns: repeat(2, 20%) 15% auto !important; 

    .message {
      padding-bottom: 0;
    }

    .commit {
      a {
        pointer-events: all;
      }
    }
  }

  &__info-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0;
    padding-top:    5px;
    padding-bottom: 5px;

    & .circle--fail .stages__info--description { color: #f84737; }

    &:first-of-type { margin-right: 10px; }

    @media (max-width: 1420px) {
      &:first-of-type { margin-right: 0; }
    }
  }   

  &__info-item { min-width: 65px;}

  &__info { 
      margin: 0 0 0 10px;
      color: #a5b8ca;

      &--description { 
        text-transform: uppercase; 
        margin: 0;
      }

      &--configure {
        color: #1b9de8;
        cursor: pointer;

        &:hover { text-decoration: underline; }
      }

  }

  // Stages container
  &-container {
    max-height: calc(100vh - 400px);
    margin-top:     20px;
    margin-bottom:   5px;
  }
}

.kube-grid {
  grid-template-columns: 200px 150px repeat(5, minmax(50px, 150px));
}