.modal {
  position: absolute;
  top:  50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  max-width: 570px;
  max-height: 80%;
  width: 100%;
  padding-right: 10px !important;

  background-color: #24323b;
  border-radius: 5px;
  color: $txt-white;
  box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  & h3 { text-align: center; }

  & .modal__title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 7px;
  }

  & .modal__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 5px 0;
  }

  & .modal__text {
    color: $txt-white;
    font-size: 16px;
    text-align: center;
  }

  &__content-section {
    background: rgb(47, 66, 76);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;

    .modal__title {
      font-size: 20px;
      margin: 0;
    }

    .content-section-infobox {
      background: #24333b;
      border-radius: 5px;
      padding: 20px;
    }
  }

  // Modal content padding
  &--padding { padding-right: 30px; }

  // Modal relative position
  &--relative { 
    position: relative !important; 
    height: auto !important;

    .modal__content{
      padding: 25px 5px 0 15px !important;
      height: 100% !important;
    }
  }

  // Line separator
  & .modal__header::before,
  &__blackbox .radiobutton::before,
  &__footer::before {
    content: "";
      position: absolute;
      left:  0;
      right: 0;
      width: calc(100% + 10px);
      margin: 0;
      height: 1px;
      background-color: #313d4f;
  }

  // Modal logo / image
  &__logo {
    margin-bottom: 25px; 

    & svg {
      display: block;
      margin: 0 auto;
    }
  }

  // Close button
  &__close {
    position: absolute;
    top:   20px;
    right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    // padding-bottom: 2px;
    max-width:    28px;
    width:        100%;
    min-height:   28px;
    border-radius: 50%;
    background-color: #16242c;
    font-size: 12px;
    transition: $transition;
    cursor: pointer;
    z-index: 11;

    &:hover { 
      background-color: #e1302a;
      color: $txt-white;
    }
  }

  // Modals with actions
  &--action {
    padding: 30px;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &  .modal__header::before { bottom: 0; }

    & .modal__text {
      max-width: 430px;
      width: 100%;
    }
  }

  // Edit modal
  &--edit {
    & .modal__header { margin-bottom: 20px !important; }
    & .modal__text   { text-align: left; }

    // Checkboxes
    & .checkbox { 
      margin-bottom: 20px;

      &__title { font-size: 14px; }
      
      &__input,
      &__label::before { top: -1px; }
      &__label::after  { top: 3px;  }
    }

    // Dropdown menues
    & .dropdown {
      max-width: 100%; 
      margin-bottom: 60px;
    }

    // Button
    & .btn { font-size: 18px; }
  }

  // Modal Action --> Replicate
  &--replicate {
    min-height: 435px;
    top: 20%;
    transform: translate(-50%, -20%);
  }

  // Modal action --> Edit
  &--edit { max-width: 520px; }

  // Modal Action --> Delete
  &--delete {
    max-width:  540px;

    & .modal__text { padding: 0 35px; }

    & .btn--lg { 
      height:    54px; 
      font-size: 18px;
    }

    // Modal padding
    & .modal--padding { padding-right: 20px; }
  }

  // Projects page
  &--projects {
    max-width: 785px;
    padding: 55px 45px;

    & h3 { 
      font-size: 28px; 
      font-weight: 700;
      margin-bottom: 60px !important;
    }

    & input,
    & textarea {
      font-family: "Roboto";
      font-size: 14px;
    }

    // Input fields (text and text area)
    .box__inputs {
      display: flex;
      flex-direction: column;
      align-items:    center;
      justify-content: flex-start;
      margin-left: 30px;
      max-width:  470px;
      width:       100%;
      height: 190px;
    }

    // Project name input field error message
    & .input__field--error-msg { bottom: -7px; }
  }

  // Amazon / Git accounts modal
  &--account {
    max-width: 540px;
    padding: 30px 40px 55px 40px;
    
    h3 { font-size: 28px; }
    p  { 
      text-align: left !important;
      margin:     20px 0;
      font-size:    16px;


      a {
        font-size: inherit;
        color: $lightBlue;
      }
    }

    & .modal__text { margin-bottom: 35px; }


    // Settings Modal Account radiobuttons
    & .radiobutton {
      margin-right: 30px;

      &:last-of-type { margin-right: 0; }

      &__mark { top: 2px; }
    }

    & .input__field ~ svg { top: 45px; }

    // Settings - management invite user modal
    & .invite-user {
      .page-content__dropdown-container { 
        margin-bottom: 120px;
      }
    }
  }

  // Modal Add to Stage
  &--configure {
    max-width: 920px;
    max-height: 95%;
    height: 100%;
    // height: 95%;
    padding: 0;

    & h6 { font-size: 16px; }

    & .dropdown {
      max-width: 100%;
      height:    77px;
    }

    & .col-6:first-of-type .dropdown { padding-right: 15px; }

    & .modal__header {
      padding-top:  15px;
      padding-left: 30px;

      &::before { bottom:  -15px; }
    }

    & .modal__content { 
      // padding: 35px 30px 0 30px; 
      padding: 31px 5px 0 15px;
      height: calc(100vh - 230px);
    }

    & .modal__title { text-align: left; }

    & .modal__info {
      position: relative;
      min-height: 50px;
      margin-bottom: 30px;
      
      & svg {
        width: 15px;
        cursor: pointer;

        & path { fill: #abbed0}
      }

      & svg:hover .modal__tooltip { opacity: 1; }
    }

    & .input__field { 
      height: 48px; 
      margin-bottom: 4px;
    }

    & .btn--lg { 
      font-size: 18px; 
      max-width: 130px;
      min-height: 38px;
    }


    // Circular progress component
    .MuiCircularProgress-root {
      display: block;
      margin: 0 auto;
      transform: translateX(50%);
      transform-origin: center;
      padding: 30px 0 70px 0;
    }
    .MuiCircularProgress-indeterminate {
      animation: MuiCircularProgress-keyframes-mui-progress-circular-rotate 1.4s linear infinite;
    }
  }

  // Deployment modal
  &--deploy {
    padding: 40px 30px;

    & h3 { margin-bottom: 15px; }

    & p { 
      max-width: 350px;
      font-size: 16px;
      text-align: center;
      color: $txt-white;
      margin: 0 auto 25px auto;
    }

    // Deployment images
    &-image { 
      position: absolute; 
      left: 50%;
      transform: translate(-50%);
    }
    
    &-person { 
      top:   50px;
      left:   60%;
    }
  }

  // Details page - blackbox
  &--add-stage {

    & .modal__header { 
      margin-bottom: 0 !important;
      padding-bottom: 15px;

      &::before { display: none; }
    }

    & .modal__text { 
      text-align: left; 
      color: #a5b8ca;
    }

    .list {
      padding-left: 0;
      padding-right: 15px;
    }
  }

  // Git accounts modal - Settings page
  &--git {
    max-width: 1203px;
    padding: 35px 30px 70px 11px;
    transform: translate(-50%, -80%);
    & .modal__header {
      flex-direction: row;
      justify-content: center;

      &::before { display: none; }
    }

    h2 { 
      font-size: 28px;
      margin-bottom: 15px;
    }
    svg { margin-bottom: 35px; }

    & .btn--lg {
      max-width: 290px;
      font-size: 18px;
    }
  }

  &__blackbox {
    background-color: #16242c;
    border-radius: 3px;
    max-width: 520px;
    width: 100%;
    min-height: 270px;
    padding: 10px 24px;

    // Radiobuttons
    & .radiobutton {
      padding: 8px 0;

      // Line under every radiobutton component
      &::before {
        bottom: -5px;
        width:  100%;
        margin:    0;
      }
    }

    .box__text {
      font-size: 16px;
      line-height: 1.3;
      text-align: inherit;
      padding-top: inherit;

      a {
        color: #4a90e2;
      }
    }
  }

  // Details page - build modal
  &--build { padding: 40px 30px 30px 30px; }

  // Footer section
  &__footer {
    position: relative;
    padding: 20px 5px;

    &::before { top:  0; }
  }
}

@keyframes MuiCircularProgress-keyframes-mui-progress-circular-rotate {
  100% {
    transform: translateX(50%) rotate(360deg) !important;
  }
}

.modal__info {
  position: relative;
  min-height: 50px;
  margin-bottom: 30px;
}