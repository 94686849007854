.inline-message {
    color: white;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;

    .message-icon {
        margin-right: 5px;
        width: 20px;
    }

    &.info-message {
        background: #2b7dc5;

        a {
            color: #16252c;
            display: contents;
            font-weight: bold;
        }

    }

    &.error-message {
        background: #f84737;
    }
}