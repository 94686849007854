// NOTIFICATIONS
.notifications {
  // Notifications side menu
  &__menu {
    position: fixed;
    top:    0;
    left:   0;
    bottom: 0;
    max-width: 410px;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    z-index: 13;
    background-color: #24323b;
    transition: 0.5s ease;

    &--open { transform: translateX(0); }
  }

  // Notifications menu header
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #2f404a;
    min-height: 70px;
    padding-left: 15px;

    & .circle--grey { background-color: #24323b;}
    svg { transform: rotate(90deg); }
  }

  // Notifications tabs menu
  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 25px;
    border-bottom: 1px solid #16242c;

    p:hover { 
      border-bottom: 1px solid #abbed0;
      cursor: pointer;
    }
  }

  &__filter {
    justify-content: flex-start !important;
    border-bottom: none;
    max-width: 200px;
    width: 100%;
  }

  // Notifications filter items
  &__item {
    font-size: 14px;
    color: #a5b8ca;
    margin: 0 10px;
    padding: 3px 10px;
    border-radius: 3px;
    transition: 0.1s ease;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.5s ease;

    &:hover {
        background-color: #2f404a;
        color: #fff;
    }

    &.active { 
      background-color: #2f404a;
      color: #fff;
    }
  }

  // Notifications sidemenu body
  &__body { padding: 15px; }

  // Notifications sidemenu cards
  &__card {
    position: relative;
    display: flex;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;

    // Circle marking the notfication as unread
    &::before {
      content: "";
      position: absolute;
      top:   18px;
      right: 10px;
      background-color: #1b9de8;
      width:  12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

    // Card underline
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 1px;
      border-radius: 4px;
      background-color: #2f404a;
    }

    // Unread notifications
    &--unread { 
      background-color: #2f404a;
      cursor: pointer;
      
      &::before {
        transform: scale(1);
        transition: 0.6s ease;
      }

      &::after { display: none; }
    }
    

    // Content
    &-content {
      margin-left: 20px;
      width: 100%;
      word-break: break-all;
      h6 { margin-bottom: 5px; }
    }

    & .circle { flex-shrink: 0; }
  }

  &__text {
    margin-bottom: 15px; 
    color: $txt-white;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    p { font-size: 12px; }

    svg { 
      width:  12px;
      height: 12px;
      margin-right: 5px;

      g { stroke: #a5b8ca; }
    }
  }

  // Notifications Preferences - Settings page
  &__preferences {
      padding: 45px 15px; 
      
      & .card__header-wrapper {
        & .card__header { padding-top: 5px; }

        &::after { background-color: #465762; }
      }

      & .card__title { font-size: 16px; }

      & .slack {
          width: 100%;
          padding-right: 10px;
      
          // Slack integration text
          h5 { 
            margin-bottom: 10px; 
            font-size:     16px;
          }

          p {
            font-size: 12px;
            margin: 10px 0 20px 0;

            a {
                font-size: inherit;
                color: $lightBlue;
            }
          }

          & .input__field {
            height: 42px;
            margin-bottom: 0;
          }
          
          & .checkbox__label::before { top: 0;   }
          & .checkbox__label::after  { top: 4px; }
      }

      & .checkbox { margin: 5px 0 0 0; }
  }

  &__content { 
    padding: 20px 0 0 10px; 
    & .btn--md { max-width: 130px !important; }
 }


  // If notifications card is last - no underline
  &__card:last-of-type::after { display: none; }
}