.steps {
  position: absolute;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  z-index: -1;
  border: 2px solid #101b21;
  height: 10px;
  background-color: #101b21;
  border-radius: 5px;

  &__item {
      position: absolute;
      top: -11px;
      display: inline-block;
      width:  32px;
      height: 32px;
      margin: 0;
      color: $txt-white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #0e1f28;
      transition: $transition;
      transition-delay: 0.3s;
      border: solid 4px #101b21;
      padding-bottom: 3px;

      svg { 
          width: 14px;
          margin-top: 3px;
      }

      &.completed { background-color: #4aa04b; }
      &.error     { background-color: $red;      }

      &::before {
          content: attr(data-number);
          margin-bottom: 3px;
      }

      &::after {
          content: attr(data-text);
          position: absolute;
          bottom: -35px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          width: 160px;
          text-align: center;
      }
  }


  &__progress {
      position: absolute;
      left: 0;
      height: 5px;
      background-color: #4aa04b;
      border-radius: 5px;
      transition: 0.5s ease;
      max-width: 100%;
      width: 20%;
  }

  &__time {
      position: absolute;
      top: 30px;
      font-size: 12px;
      color: #abbed0;
      font-weight: bold;
  }
}
