.statistics-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & .btn {
    margin-top: 8px;
    @media (max-width: 1200px) {
      margin: 16px 0;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
}

.aws-dropdown-fields {
  width: 70%;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }

  & > div > .card_header_container {
    display: none;
  }

  & > div > .page-content__dropdown-container {
    padding: 0;
    width: 45%;

    @media (max-width: 1200px) {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
}

.statistics-content {
  min-height: auto !important;
  padding: 15px 14px 5px 15px !important;

  & .general-info {
    padding: 0 14px;
  }

  &:not(:first-of-type) {
    line-height: 37px;
  }

  & thead th {
    width: 10%;
  }

  & thead th:nth-child(1) {
    width: 12%;
  }

  & thead th:nth-child(2) {
    width: 14%;
  }

  & .status-icon {
    margin-right: 10px;

    &.status-ok {
      padding: 6.9px;
    }

    &.status-unknown {
      color: #96b0bc;
    }
  }

  & .details__title {
    display: flex;
    align-items: center;
  }

  & .details__subtitle {
    white-space: pre-line;
    font-size: 14px;
    line-height: 1.3;
  }

  & .icon-placeholder {
    width: 30px;
    height: 30px;
    position: relative;
    left: 0;
    border-radius: 50%;
    margin-right: 10px;
  }

  & .summary-placeholder {
    width: 250px;
    height: 20px;
    position: relative;
    left: 0;
    margin: 10px 0 10px;
  }

  & .summary {
    white-space: pre-line;
    margin-top: 20px;
  }
}
