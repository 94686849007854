.schedule {
    padding: 0;
    margin: 25px 14px;

    &__sidebar {
        background-color: #24323b;
        padding: 20px;
        border-radius: 6px;

        .checkbox {
            margin: 0px 0;
        }
        .sidebar__title {
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        .sidebar__subtitle {
            margin: 10px 0;
            color: white;
        }
    }

    &__content {
        padding: 0 30px;

        .details {
            &__header{
                color: gray;
                margin: 17px;
                width: 97%;
                display: block !important;
                
                div {
                    float:right;
                }

                a {
                    color: #4a90e2;
                    cursor: pointer;
                    margin-left: auto;
                    margin-top: 5px;
                }
            }

            &__price_explanation {
                margin-top: 0px;
            }

            &__affected {
                margin-bottom: 2px;
            }
        }
    }
}

.cron > .row > .col-6 > .checkbox {
    margin: 0px 0;
} 

.cron {
    margin-bottom: 20px;
    // border-bottom: solid 1px #5a646e;
    padding-bottom: 20px;

    &__title {
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}