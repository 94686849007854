.radiobutton {
  display: block;
  position: relative;
  font-size: 16px;
  margin: 10px 5px;
  color: #fff;
  cursor: pointer;

  // Radiobutton input
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width:  0;
  }

  // Text representing the label
  &__text { padding-left: 30px; }

  // Radiobutton description - sublabel(?)
  &__description {
    font-size: 14px;
    color: $txt-grey;
    padding-left: 30px;
  }

  // Radiobutton mark representing the dot
  &__mark {
    position: absolute;
    top:  8px;
    left: 0;
    height: 20px;
    width:  20px;
    border-radius: 50%;
    background-color: #060e13;
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

    &::after {
      content: "";
      position: absolute;
      opacity: 0;
      transform: scale(0);
      top:  5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #eee;
      transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

  }

  // Radiobutton on hover
  &:hover &__mark { background-color: $green;}
  &:hover &__mark::after { 
    opacity: 1; 
    transform: scale(1)
  }

  // Checked radiobutton style
  &__input:checked ~ .radiobutton__mark::after { opacity: 1; transform: scale(1); }
  &__input:checked ~ .radiobutton__mark { background-color: $green; }
}