.cluster {
  position: relative;

  &__sidemenu {
    max-width: 360px;
    width: 100%;
    height: 100%;
    position: fixed;
    top:    0;
    right:  0;
    bottom: 0;
    z-index: 11;
    background-color: #24323b;
    // padding: 20px 10px 20px 15px;
    transform: translateX(100%);
    transition: 0.5s ease;


    // Sidemenu search bar
    & .searchbar__container { padding: 15px 0 7px 0; }
    & .searchbar__icon,
    & .input__field { height: 36px; margin-bottom: 0; }

    // Searchbar placeholder text
    & .input__field { 
      font-size: 14px; 
      opacity: 0.5;
    }

    // If sidemenu is open
    &--open { transform: translateX(0%); }
    &--open .cluster__side-btn svg { transform: rotate(-90deg); }
  }

  // Header
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 65px;
    padding: 0 15px;
    background-color: #2f404a;
    border-bottom: 1px solid #16242c;
  }

  // Container for searchbar and cards
  &__container { 
    padding: 0 10px 20px 15px;
  }

  // Toggle cluster sidemenu Button
  &__side-btn {
    position: absolute;
    left: -105px;
    top: 220px;
    background-color: #0287cc;
    max-width: 170px;
    width: 100%;
    height: 43px;
    z-index: -1;
    border-radius: 3px;
    transform: rotate(-90deg);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;

    & svg {
      width:  6px;
      height: 16px;
      transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      transition-delay: 0.1s;
      transform: rotate(90deg);

      & path { fill: #0287cc; }
    }
  }

  // Cluster sidemenu cards
  & .card { 
    min-height: 0; 
    margin: 10px 0;

    // Card text
    & p { font-size: 12px; }
    
    // Cluster sidemenu cards buttons
    & .btn { 
      margin: 10px 0 0 0; 
      font-size: 12px;

      & svg { 
        width:  14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }

}