/*----------------------------------------------------------------------------
                                #MAIN_SECTION
---------------------------------------------------------------------------*/

.content {
    width: 100%;
    min-height: 99vh;
    padding: 0 40px 0 380px;
    transition: 0.3s ease;
    @media (max-width: 1400px) {
        padding: 0 20px 0 340px;
    }
    &--projects {
        padding-left: 120px;
    }
    &__header {
        min-height: 82px;
        padding: 10px 0;
        border-bottom: 1px solid #333f46;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.3s ease;
        @media (max-width: 1400px) {
            min-height: 72px;
        }
        &--center {
            justify-content: center;
        }
        .header__items {
            display: flex;
            align-items: center;
            & svg {
                width: 22px;
                height: 22px;
                transition: $transition;
                &:hover path {
                    fill: #abbed0;
                }
            }
            &>* {
                margin: 0 10px;
                cursor: pointer;
            }
        }
        // Header buttons that are supposed to link to another page.
        & a {
            max-width: 180px;
            width: 100%;
            margin: 0;
        }
        // Content header buttons (if there are multiple buttons available)
        &__buttons {
            max-width: 540px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            &>* {
                margin: 0 5px !important;
            }
            & .btn--md {
                max-width: 140px !important;
            }
            & .btn--link {
                max-width: 180px !important;
            }
            // Dropdown menu button
            & .dropdown__menu__btn {
                background-color: #24323b;
            }
            // Dropdown container menu
            & .dropdown {
                max-width: 194px;
                height: 37px;
                &__menu {
                    padding-top: 3px;
                }
                &__arrow {
                    margin-left: 0;
                }
                &__title {
                    position: relative;
                    padding-left: 30px;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 8px;
                        top: 12px;
                        width: 14px;
                        height: 14px;
                        display: inline-block;
                        background-image: url("../../static/projects-icon.svg"); // Treba da se smeni ikonkata
                        background-size: cover;
                    }
                }
                &__item {
                    max-width: 100%;
                }
            }
        }
    }
    &__body {
        padding: 25px 0 30px;
        min-height: calc(100vh - 160px);
        transition: 0.3s ease;
        @media (max-width: 1400px) {
            padding: 20px 0 20px;
        }
    }
}