.skeleton {
  position: relative;
  background-color:#384a55;
  border-radius: 5px;

  // Skeleton placeholder items (title, description, image etc.)
  &--placeholder { 
    position: absolute;
    z-index: 10;
    display: block;
    left: 10px;
    background-color: #4a6372;
    height: 15px;
  }

  &--animation {
    background-color: #4a6372 !important;
    background-image: linear-gradient(90deg, #4a6372, #557081, #4a6372) !important;
    background-size: 200px 100%;
    line-height: 1;
    background-repeat: no-repeat;
    border-radius: 4px;
    animation: skeleton 1.2s ease-in-out 0s infinite normal none running;
  }
  
  // Skeleton Header elements
  &__header {
    background-color: transparent;

    * {
      position: relative;
      left: inherit;
      margin: 10px 0;
    }

    h5 {
      width: 150px;
      height: 30px;
    }

    h6 {
      width: 150px;
      height: 20px;
    }

    p {
      width: 250px;
      height: 20px;
    }

  }

  // LISTS
  &__lists {
    width: 100%;
    height: 59px;
    margin-bottom: 10px;

    &.col-4 {
      height: 260px;

      & .box {
        height: 80px;
        width: 100%;
        position: inherit;
      }
    }

    // Title
    h5 {
      width: 150px;
      top: 12px;
    }

    // Description
    p {
      width: 250px;
      top:  32px;
    }

    // Statuses circle
    &--circle {
      left:  inherit;
      right:  20px;
      top:    22px;
      width:  12px;
      height: 12px;
    }

    &--management {
      * {
        position: relative;
        top: 5px;
        left: inherit;
        margin: 0 10px;
      }
      
      h5,
      p { top: 5px; }
    }
  }

  // BOXES
  &__box {
    max-width: 330px;
    width: 100%;
    height: 250px;

    // Skeleton box header
    h5 {
      width: 150px;
      background-color: #4a6372;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -40%);
    }
    p {
      width: 250px;
      background-color: #4a6372;
      left: 50%;
      top: 60%;
      transform: translate(-50%, -60%);
    }

    // Project box footer
    & .skeleton__footer {
      bottom: 0;
      left:   0;
      width: 100%;
      height: 40px;

      span {
        width:  24px;
        height: 24px;
        border-radius: 50%;
        position: relative;
        left: inherit;
        margin-right: 20px;
      }
    }
  }

  // Project boxes / cards logos
  &__logo {
    width:  90px;
    height: 90px;
    background-color: #384a55;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -30px);
    border: 5px solid #0e1f28;
  }

  // Stages page skeleton cards
  &__card {
    width: 100%;
    min-height: 140px;
    padding: 10px;
    margin: 30px 0;
    
    h5, p { background-color: #506a77;}

    // Skeleton content
    & .skeleton__content {
      background-color: transparent;

      &.skeleton--item {
        max-width: 181px;
      }

      .skeleton__circle {
        width: 34px;
        height: 34px;
        background-color: #506a77;
        border-radius: 50%;
        top: 5px;
      }

      h5,
      p { left: 60px; }

      h5 { 
        width: 80px; 
        top:    5px;
      }

      p {
        width:  100px;
        bottom: -10px;
      }
    }

    // Deployment Plan page cards
    &--deployment {
      display: flex;
      min-height: 100px;

      .skeleton__content { 
        max-width: 250px;
        top: 20px;
      }
    }
  }

  // Skeleton timeline items
  &__timeline {
    min-height: 40px; 
    background-color: transparent;
    margin-bottom: 30px;

    h5 {
      width: 100px;
      left: 40px;
    }

    p {
      width: 150px;
      left: 40px;
      bottom: -15px;
    }

    // Status circle
    span {
      width:  12px;
      height: 12px;
      left: 10px;
      display: block;
    }
  }

  // Skeleton table
  &__table {
    width: calc(100% + 30px);
    margin: 20px -15px 0 -15px;

    span { 
      position: relative;
      display: block;
      border-radius: 3px;
      background-color: #506a77;
      height: 20px;
    }

    thead {
      th { background-color: #24323b; } 
      
      span {
        width: 80px;
        margin: 0 auto;
      }
    }

    tbody {
      tr { margin: 10px 0; }

      td { padding: 8px !important; }

      td span {
        width: 120px;
        margin: 10px auto;
      }
    }

    // Clusters table
    &.clusters thead th { background-color: #2f404a !important; }
  }

  // Skeleton notifications
  &__notification {
    width: 100%;
    height: 120px;
    margin-bottom: 20px;

    .skeleton__image {
      top:  20px;
      left: 20px;
      width:  40px;
      height: 40px;
    }

    h5 {
      top: 20px;
      left: 80px;
      width: 100px;
      height: 14px;
    }

    p {
      top:  45px;
      left: 80px;
      width: 70%;
      height: 40px;
    }

    & .skeleton__project-name {
      top: 80%;
      z-index: 12;
      left: 80px;
      width: 80px;
      height: 15px;
    }

    & .skeleton__project-time {
      top: 80%;
      left: 70%;
      width: 70px;
      height: 15px;
    }
  }

  // Skeleton content
  &__content {
    width: 100%;
    height: 50px;
    position: relative;
    left: 0;
    top: 5px;
  }

  // Skeleton buttons
  &__button {
    width: 150px;
    height: 30px;
    position: absolute;
    left: inherit;
    right: 20px;
  }

  // Images
  &__image {
    width:  34px;
    height: 34px;
    left: inherit;
  }

  &__card-item.skeleton__image { 
    width: 35px;
    height: 35px;
  }
  // Circles
  &--circle { border-radius: 50%;}
}

// react-skeleton-loading library components styling
.css-19bon8n {
  background-color: #384a55 !important;
  background-image: linear-gradient(90deg, #384a55, #4a6372, #384a55) !important;
}

@keyframes skeleton {
  0% {
    background-position: -200px 0px;
  }
  100% {
    background-position: calc(200px + 100%) 0px;
  }
}