// Background colors
$bg-dark-grey: #050e14;
$bg-lighter-grey: #07141b;
$bg-grey: #0e1f28;

// Colors
$txt-white: #fff;
$txt-grey: #96b0bc;

// Card colors
$card-bg: #24323b;

// Global colors
$red: #f84737;
$green: #67be68;
$light-green: #67be68;
$yellow: #f5a623;
$blue: #007dc5;
$blue50: #007dc570;
$lightBlue: #4a90e2;

$amazon-yellow: #f39c12;
$git-red: #e7402e;
$git-hub: #24292e;

// Status colors
$status-done: #4abb59;
$status-progress: #f5a623;
$status-fail: rgba(223, 69, 56, 0.46);

// Transition
$transition: 0.2s ease;

// Dropdowns colors
$dropdown-bg: #16242c;
$dropdown-txt: #abbed0;

