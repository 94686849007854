.cards-dashboard {
    padding: 10px 0;

    .card-item {
        margin: 15px 0;
        padding: 20px;
        background-color: #2f404a; 
        border-radius: 5px;
        height: 260px;
        cursor: pointer;

        & .card-icon {
            background: white;
            width: 55px;
            padding: 13px;
            border-radius: 5px;
        }

        & .card-text {
            font-size: 16px;
        }

        & .components-footer .circle {
            border-radius: 5px;
        }
        
        & .list__icons {
            & * {
                margin: 0;
            }
            & .dropdown__icon {
                margin: 0 5px !important;

                & * {
                    margin: 0 5px !important;
                }
            }
            & .dropdown__menu__btn {
                transform: rotate(90deg);    
            }       
        }
    }
}