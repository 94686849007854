.markdown-viewer {
    padding: 25px;
    
    ol,
    ul {
        list-style: disc;
        margin-left: 40px;
        margin-top: 12px;
        margin-bottom: 20px;
    }
  
    li {
      margin-bottom: 10px;
    }

    p, a, ul li, ol li {
        font-size: 16px;
    }
  
    p {
      color: #fff;
      margin-top: 0;
      margin-bottom: 12px;
      margin-top: 12px;
    }
  
    a {
      color: #4a90e2;
    }
  
    blockquote {
      padding-left: 20px;
      border-left: solid 8px #4c90e2;
      margin: 8px 0px 0px;
    }

    h1 {
      font-size: 29px;
      font-weight: 600;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
    }

    h4 {
      font-size: 14px;
      font-weight: 600;
    }

    h1, h2 {
      margin-top: 40px;
    }

    h3 {
      margin-top: 28px;
    }

    h1:first-child, h2:first-child {
      margin: 0;
    }
  
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 12px;
    }
    
    img {
      max-width: 35%;
    }
  
    pre {
      white-space: pre-wrap;
      background-color: #16252c;
      padding: 13px;
      line-height: 24px;
      border-radius: 5px;
      margin: 20px 0;
    }
  
    code {
      color: white;
      padding: 0.2rem 0.5rem;
      background-color: #272b36;
      border-radius: 5px;
      margin-left: 5px;
    }
  
    table {
      width: 100%;
      text-align: left !important;
      border: 1px solid #313d4f;
      color: #fff;
      overflow: scroll;
      margin-left: 0;
      margin-bottom: 20px;
  
      thead {
        background-color: #2f404a;
        th {
          padding: 10px 20px;
          font-size: 16px;
          font-weight: 700;
        }
      }
  
      tbody {
        td {
          padding: 10px 20px;
        }
        
        tr:nth-child(odd) {
          background-color: #24323b;
        }
        tr:nth-child(even) {
          background-color: #16242c;
        }
      }
    }
  }