.details {
  &--dark { background-color: #101b21; }

  // Details rows
  &__row { padding: 26px 25px 35px 25px; }

  // List
  &__list {
    padding-left: 20px;

    &__item {margin: 10px 0;}
  }

  // Details items
  &__item { 
    margin-bottom: 40px;
  
    &:last-of-type { margin-bottom: 0;}
  }

  // Details item title
  &__title  { margin-bottom: 15px; }
  &__text   { 
    color: $txt-white; 
    margin-bottom: 25px;
  }
  &__subtitle { 
    color: #a5b8ca; 
    margin-bottom: 25px;
    text-align: justify;
  }
 
  // Details container for code block - Build/Deployment details page
  &__container {
    background-color: #16242c;
    padding:       20px;
    padding-right: 10px;
    overflow-y: auto;
    height: 109%;
    
    &--full {
      min-height: calc(100vh - 360px);
    }

    & .details__code-block { 
      padding: 0; 
      padding-right: 20px 
    }
  }

  // Code block
  &__code-block {
    display: block;
    background-color: #16242c;
    width: 100%;
    min-height: 649px;
    max-height: 225px;
    padding: 25px; 
    
    
    & p { 
      display: block; 
      margin-bottom: 0;
      color: #fff;
      font-family: monospace;
      font-size: 14px;
    }
  
  
    & div { margin-bottom: 20px; }
  }
  
  // DETAILS STATUS - MERGE WITH DETAILS SCSS FILE WHEN "feature/component-details" BRANCH IS MERGED WITH DEVELOP
  &__status {
    min-height: 56px;
    border-radius: 6px;
    margin-bottom: 10px;
    background-color: #24323b;
    display: flex;
    align-items: center;

    & .stages__info-wrapper {
      max-width: 100%; 
      grid-template-columns: repeat(2, 1fr) 350px 1fr;
    }

    // Deployment details info wrapper
    &--deployment .stages__info-wrapper { 
      grid-template-columns: repeat(3, 1fr); 
      
      .stages__info-container { padding: 0 15px; }
    }

    // Microservice details page header
    &--microservice .stages__info-wrapper { grid-template-columns: repeat(5, 1fr); }
    
    // Info 
    & .stages__info-container  { 
        justify-content: flex-start;
        min-width: auto;
        margin: 0 25px;
        padding-left: 5px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left:   0;
            width: 1px;
            height: 100%;
            background-color: #2f404a;
        }
        &:first-of-type::before { display: none; }
        
        &:first-of-type { margin: 0; }

        & .stages__info--description { 
          font-size: 12px; 

          .tooltip {
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
            margin-left: 0px;
            width: 70px !important;

            &::before {
              left: 100%;
              min-width: 150px;
            }

            &::after {
              left: 0;
            }

            .build {
              color: #4a90e2;
              cursor: pointer;
            }
          }
        }
        & .circle--placeholder { 
          background-color: #2f404a;
          margin-left: 7px;
          
          & svg g,
          & svg path { stroke: #abbed0; }
        }
        // If status is fail - display text in red
        & .circle--fail ~ .stages__info .stages__info--description { color: red; }
      }

      & .stages__info-container:not(:first-of-type) .stages__info--description { text-transform: lowercase; }

      .stages__info-button {
        pointer-events: auto;
      }
    // Checkbox (Microservice Logs page)
    & .checkbox__label::before { top: 0;   }
    & .checkbox__label::after  { top: 4px; }
  }

  // Details table
  & .table {
    // max-width: 370px;
    width: 100%;
    text-align: left !important;
    border: 1px solid #313d4f;
    color: $txt-white;
    overflow: scroll;
    margin-left: 0;

    * { word-break: initial;}

    // Table headers 
    & th {
      color: #abbed0;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }

    & td::after { display: none; }

    // Table data
    & td { font-size: 14px; }
    & td:first-of-type { font-weight: bold; }

    & th,
    & td { padding: 10px 20px; }

    // Table row
    & th { background-color: #16242c;}
    & tr:nth-child(odd) { background-color: #24323b;}
    & tr:nth-child(even) { background-color: #16242c;}

    & th:first-of-type { width: 140px; }
    & th:last-of-type,
    & td:last-of-type { width: 135px; text-align: left !important; }
  }

  // Details overlay and info
  &__overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width:   100%;
    height: 100vh;
    margin-right: 30px;
    // margin-left: 380px;

    &::before {
      content: '';
      position: absolute;
      top:  0;
      left: 0;
      background-image: url("../../static/details-bg.svg");
      background-repeat: no-repeat;
      background-size:       cover;
      width:  100%;
      height: 100%;
      opacity: 0.2;
      margin-top: 35px;
    }
  }

  &__cta {
    padding-top: 120px;
    text-align: center;
    z-index: 10;

    svg { margin-bottom: 25px; }
    
    h4 { 
      font-size:     22px; 
      margin-bottom: 25px;
    }

    p {
      max-width: 530px;
      width:     100%;
      font-size: 16px;
      color: #fff;
      margin-bottom: 30px;
    }

    & .btn--lg { max-width: 280px; }

  }

}