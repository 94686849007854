input[type=number]::-webkit-inner-spin-button {  
  width: 20px;
  height: 48px;
}

.input {
  position: relative;
  width: 100%;

  // Login / Signup inputs
  &--login {
    margin-bottom: 30px;

    & .input__field { margin-bottom: 0; }
    & .input__field::placeholder { font-size: 14px; }
  }

  // Animated placeholder
  .animated-placeholder {
    position: absolute;
    top:  17px;
    left: 20px;
    color: #abbed0;
    font-size: 14px;
    transition: 0.5s ease;
    text-transform: capitalize;
  }
  
  &__field:focus ~ .animated-placeholder,
  &__field:valid ~ .animated-placeholder {
    top:   2px;
    font-size: 12px;
    color: #fff;
  }

  &__field {
    background-color: #16242c;
    border-radius: 3px;
    height: 50px;
    width: 100%;
    border: none;
    color: #abbed0;
    text-indent: 20px;
    margin-bottom: 20px;
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
  
    &::placeholder {
      color: #5f6973;
      text-indent: 20px;
      transition: 0.5s ease;
      transition: 0.3s ease;
    }

    &:active::placeholder,
    &:focus::placeholder { opacity: 0; }

    &.sensitive {
      -webkit-text-security: disc;
    }

    &--disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }

    &--error {
      &::before {
        content: "";
        position: absolute;
        top:  0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(248, 71, 55, 0.2);
      }

    //   & ~ svg {
    //     position: absolute;
    //     right: -22px;
    //     top:    16px;
    //     width:  16px;
    //     height: 16px;

    //     & .fa-primary   { fill: white;   }
    //     & .fa-secondary { 
    //       fill: #f84737;
    //       opacity: 1;
    //     }

    //   }
    }

    // If there is error message
    &--error-msg {
      position: absolute;
      height: 24px;
      bottom: -27px;
      left: 3px;
      font-size: 12px;
      line-height: 1;
      color: #f84737;
    }

    &--username { background-image: url("../../static/username-icon.svg")}
    &--password { background-image: url("../../static/password-icon.svg")}
    &--email    { background-image: url("../../static/email-icon.svg")}
    &--confirm  { background-image: url("../../static/repeat-password-icon.svg")}
    &--link     { 
      background-image: url("../../static/link-icon.svg");
      padding-right: 45px;
    }
  }

  &__toggle {
    position: absolute;
    right: 13px;
    top: 15px;
    color: #017dc6;
    font-weight: bold;
    cursor: pointer;
  }

  &__toggle__label {
    top: 44px;
  }

  // Input fields with error messages
  &__field--error,
  &-textarea--error {
    & ~ svg {
      position: absolute;
      right: -22px;
      top:    16px;
      width:  16px;
      height: 16px;

      & .fa-primary   { fill: white;   }
      & .fa-secondary { 
        fill: #f84737;
        opacity: 1;
      }

    }
  }

  &-textarea--error ~ .input__field--error-msg { bottom: -20px; }

  &--textarea {
    padding: 10px 0 10px 20px;
    height: auto !important;
    text-indent: 0;

    &::placeholder { text-indent: 0 }
  }

  // Labels
  &__label {
    display: inline-block;
    position: relative;
    margin-bottom: 10px !important;
    color: $txt-white;
    font-size: 16px;
  }
}

// Autocomplete inputs styling
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #16242c;
  -webkit-text-fill-color: #abbed0;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

// Input fields on places where PageContent will be used (Create Component,  Create Microservice etc.)
.page-content {
  & .input__field { height: 36px; }

  & .input__field--error ~ svg,
  & .input-textarea--error ~ svg { top: 30px; }
}

// Material ui input overrides
.material-ui-input {
  border: 0 !important;

  input {
    color: #abbed0;
    font-size: 13px;
    font-family: Roboto;
    margin-left: 7px;
  }
  
  button {
    color: #abbed0;
  }
  
  .MuiAutocomplete-tag {
    color: #abbed0;
    background-color: #24323d;
    border-radius: 50px;

    span {
      color: #abbed0;
    }
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 7px !important;
  }
}
.MuiAutocomplete-paper{

  background-color: #16242c !important;
}

.MuiAutocomplete-noOptions{
  color: #abbed0 !important;
}

.MuiAutocomplete-hasClearIcon{
  :hover{
    border: 0px solid white;
  }
}