 $accent-color: #4abb59;

 .billing {

     .plan {
         box-sizing: border-box;
         width: 400px;
         min-height: 558px;
         border: 3px solid #0e1f28;
         border-radius: 7px;
         display: inline-block;
         background-image: url("../../static/bg.svg");
         background-repeat: no-repeat;
         background-size: cover;
         padding: 24px;
         text-align: center;
         transition: margin-top .5s linear;
         position: relative;
         margin-right: 11px;

         &__popular {
             border-color: $accent-color;

             &::before {
                 width: 0;
                 height: 0;
                 border-style: solid;
                 border-width: 64px 64px 0 0;
                 border-color: #4abb59 transparent transparent transparent;
                 position: absolute;
                 left: 0;
                 top: 0;
                 content: "";
             }

             &::after {
                 @extend .star;
                 color: white;
                 position: absolute;
                 left: 9px;
                 top: 7px;
                 text-shadow: 0 0 2px #37c5b6;
                 font-size: 1.4rem;
             }
         }

         .plan-content {
            display: flex;
            flex-direction: column;
            height: 100%;

            .plan-features {
                flex-grow: 1;
                height: 100%;
            }
         }

         .ul {
             list-style: none;
             padding: 0;
             text-align: left;
             margin-top: 29px;
         }

         .li {
             margin-bottom: 15px;
             font-size: 1.1rem;
             color: #fff;

             &:before {
                 @extend .checkmark;
                 font-size: 1.3rem;
                 color: $accent-color;
                 margin-right: 3px;
             }
         }

         .name {
             color: white;
             font-weight: 600;
             font-size: 2rem;
             margin-top: -5px;
         }

         &.star {
             font-family: "FontAwesome";
             content: url("../../static/star.svg");
         }

         .checkmark {
             font-family: "FontAwesome";
             content: url("../../static/checkmark.svg");
         }
     }

     .contact {
         padding-top: 15px;
         padding-bottom: 15px;
         border: 3px solid #0e1f28;
         border-radius: 5px;
     }
 }