.menubar {
    min-height: 100vh;
    width:  260px;
    background-color: $bg-lighter-grey;
    transition: 0.3s ease;

    @media (max-width: 1400px) {
        width: 240px;
    }

    &.shown { transform: translateX(-100%); }
      
    &__header { 
        padding: 20px;
        .strong {
            text-transform: capitalize;
        }
     }

    &__trigger {
        position: relative;
        overflow: hidden;
        width:  25px;
        height: 15px;
        cursor: pointer;

        span {
            position: absolute;
            top:    0;
            left:   0;
            width: 100%;
            height: 2px;
            background-color: #fff;
            transition: .3s ease;
            &:last-child {
                top:   10px;
                left: -10px;
            }
        }

        &:hover {
            span:last-child { left: 0; }
        }
    }
}

.menu {
    padding: 10px;
    padding-right: 0;

    &__item { 
        margin-bottom: 10px;
        margin-right:  10px;
        background-color: #24323b;
        border-radius: 3px;

        &.active:hover .menu__arrow::before { transform: rotate(-90deg)};
        &:hover .menu__arrow::before { transform: rotate(0deg); }

        // Arrow animation on hover for Settings menu link
        &:hover .menu__title--arrow::before { 
            opacity: 1;
            transform: translateX(18px) scale(1) rotate(-90deg);
        }
    }

    &__users { padding: 10px; }

    &__online { margin-left: 5px; }

    &__link { 
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 0;
        padding: 18px 30px 18px 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        font-family: "Lato";
        color: $txt-white;
        transition: $transition;
        word-break: break-word;
        cursor: pointer;

        &--active { 
            background-color: #007dc5; 
            border-radius: 3px;
            transition: 0.5s;
        }
    }

    &__group { 
        margin-bottom: 10px;
        max-height: 52px;
        transition: 0.5s ease-in-out;
        overflow: hidden;

        &--active { max-height: 215px; }
        &--highlight { background-color: red;}
    }

    &__arrow {
        position: relative;

        &::before {
            content: url('../../static/arrowIcon.svg');
            transform: rotate(-90deg);
            position: absolute;
            right: 15px;
            top:   18px;
            transition: 0.5s ease;
        }
    }

    &__title { 
        margin-left: 10px;
        font-size: 12.1px;

        &--arrow {
            width: 100%;
            position: relative;

            &::before {
                content: url("../../static/arrowIcon.svg");
                position: absolute;
                right: 10px;
                top: 1px;
                opacity: 0;
                transform: translateX(-10px) scale(0) rotate(-90deg);
                transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
            }
        }
    }
    
    &__drop-icon { transform: rotate(-90deg); }

    &__link:hover { color: $txt-white; }
}

// Animating submenu links when menu__item is clicked
.menu__item.active {
    .menu__arrow::before { transform: rotate(0deg); }
}

.submenu {
    display: block;

    &__item {
        font-size: 16px;
        font-family: "Lato";
        font-weight: 400;
        margin: 0;
        transition: 0.3s ease;

        &:hover { background-color: rgba(255,255,255,0.05) }

        a {
            display: block;
            margin-bottom: 0;
            padding: 15px 0 15px 40px;
        }
    }

    &__link { 
        font-size: 16px;
        
        &--active { background-color: #0e1f28; }
    }
}
