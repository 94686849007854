

.box {
  position: relative;
  max-width: 512px;
  width: 100%;
  background-color: #2f404a;
  border: none;
  border-radius: 5px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

  &--padding-lg { padding: 42px 42px 60px 42px;}

  // PROJECTS BOXES
  &--projects {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 250px;
    height: 100%;
    border-radius: 5px;
    transition: $transition;
    background-color: #24323b;

    cursor: pointer;
    &:hover { 
      background-color: #24323b;
      box-shadow: 0 10px 15px rgba(0,0,0,0.4);
    }

    &:last-of-type { margin-right: 0; }
  }

  // Empty boxes (projects / create project pages)
  &--empty { 
    margin: 0 30px 30px 0;
    border: 2px dashed #405058;
    background-color: transparent;

    
    // Add new project icon (+)
    & svg {
      width: 70px;
      height: 70px;
      transition: 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

    &:hover svg { transform: translateY(-20px) scale(1.3); }
  }

  &__content {
    padding: 0 15px 15px 15px;
    width: 100%;
    text-align: center;
    flex-grow: 1;
    word-break: break-all;
  }

  &__title { 
    width:      100%;
    text-align: center;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 20px;
    transition: $transition;
    word-break: break-all;

    &:hover { color: $blue; }
  }

  &__text {
    text-align: center;
    margin-bottom: 0;
  }

  &__footer {
    width: 100%;
    height:  40px;
    padding: 22px;
    border-top: 1px solid rgba(184, 184, 184, 0.5);

    & p { 
      margin: 0;
      text-align: center;
    }
  }

  // Project Dropdown Menu
  &__menu {
    position: absolute;
    top:   20px;
    right: 20px;

    & svg { 
      width:  16px;
      height: 16px;
      transform: scale(1);
    }
  }

  // Project Logo / Image
  &__avatar {
    position: relative;
    margin: 0 auto;
    transform: translateY(-20px);
    width:  90px;
    height: 90px;
    border-radius: 50%;
    background-color: #0e1f28;

    & img {
      width:  100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  // Create project box
  &--create {
    max-width: 190px;
    width:      100%;
    height:    190px;
    padding:       0;
    background-color: #2f404a;
    margin: 0;
    border: 1.5px dashed #536269;
    transition: $transition;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover { box-shadow: 0 5px 10px rgba(0,0,0,0.5); }
  }

  // Dropdown menu background color on hover (default)
  .dropdown__btn:hover { background-color: #2f404a; }
  .dropdown__menu.open .dropdown__menu__btn { background-color: #2f404a; }
  
  // Dropdown menu background color on hover (if Light theme is chosen)
  &.light {
    background-color: #2f404a;
    .dropdown__btn:hover { background-color: #24323b; }
    .dropdown__menu.open .dropdown__menu__btn { background-color: #24323b; }
  }

  // My Account box
  &--account {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 670px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2);

    & .input__field {
      margin-bottom: 15px;
    }

    // Box content
    & .box__content {
      background-color: #24323b;
      padding: 50px 40px 40px 30px; 
      animation: test 1s ease forwards;
    }
  }

  &__tab {
    width: 100%;
    padding: 20px 25px 15px 25px;
    cursor: pointer;
    background-color: #16242c;
    transition: 0.5s ease;

    h5 { 
      font-size:    16px; 
      margin-bottom: 5px;
    }

    p  { opacity: 0.5; }

    &--selected { 
      background-color: #24323b;

      p { opacity: 1; }
    }
  }
  
  &__profile-picture {
    max-width:   180px;
    width:        100%;
    height:      180px;
    margin-right: 20px;
    background-color: #16242c;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}