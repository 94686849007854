// Modal Tooltip
.tooltip {
  position: absolute;
  top:      3px;
  left:     100%;
  z-index:  10;
  display:  block;
  width:    16px;
  height:   16px;
  justify-content: center;
  margin-left: 6px;
  cursor: pointer;

  &::before {
      content: attr(data-tooltip);
      display: none;
      position: absolute;
      left:   0;
      top: -15px;
      transform: translateX(25px);
      z-index: 12;
      opacity: 0;
      padding: 10px;
      min-width: 250px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      border-radius: 3px;
      background-color: #16242c;
      box-shadow: 0 0 5px rgba(0,0,0,0.3);
      transition: 0.3s ease;
  }

  &::after {
      content: "";
      display: none;
      position: absolute;
      top:  4px;
      left: 20px;
      width:  10px;
      height: 10px;
      z-index: 1;
      transform: rotate(45deg);
      background-color: #16242c;
      opacity: 0;
      transition: 0.4s ease;
  }

  &:hover::before,
  &:hover::after { 
      display: block;
      opacity: 1;
  }

  &--build-details::before {
    top: 25px;
    bottom: initial;
    min-width: 300px !important;
    min-height: 30px !important;
  }
  &--build-details::after {
    top: 20px;
    left: calc(50% + 10px) !important;
  }
}

.MuiTooltip-tooltip {
  background-color: #16242c !important;
}