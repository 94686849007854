.btn {
  color: $txt-white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; 
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  text-transform: capitalize;
  transition: 0.2s ease;

  &:hover { box-shadow: 0 5px 10px rgba(0,0,0,0.3); }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;    
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;   
  }

  &:hover:disabled { box-shadow: none; }

  // Cluster link button
  &--cluster { 
    display: flex;
    justify-content: center;
    align-items: center;
    width:  60px;
    height: 41px;
    margin-bottom: 4px;
    margin-left: 15px !important;
    border-radius: 4px;
    background-color: #007dc5;
    cursor: pointer;
  }

  // Button sizes
  &--xs { 
    max-width: 80px;
    width:     100%;
    height:    20px;
  }

  &--sm {
    width:      110px; // ?
    height:     36px;
    font-size:  14px;
    margin: 0;
  }

  &--md {
    max-width: 180px !important;
    width:      100%;
    height:     40px;
    font-size:  14px;
    margin: 0;
  }

  // Create component/microservice/stage button
  &--create { max-width: 155px; }

  &--lg {
    max-width: 220px;
    width: 100%;
    min-height: 48px;
    font-size:  16px;
    margin: 0;
  }

  // Create Stage connect AWS account button
  &--stage-connect { 
    max-width: 305px;
    min-height: 54px;
    font-size:  18px;
  }

  &--xl {
    max-width: 450px;
    width:      100%;
    height:     54px;
    font-size:  18px;
  }

  //Linked button
  &--linked {
    display: inline-block;
    height: 100%;
    width: 50px;
    border-radius: 0px 4px 4px 0px;
    margin-left: 2px;
    padding: 0;
  }

  &--tooltip {
    max-width: 40px;
    margin: 0 15px;
    position: relative;

    &::before {
      content: attr(data-tooltip);
      position: absolute;
      display: none;
      opacity: 0;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 100px;
      padding: 8px;
      border-radius: 5px;
      background-color: #2b3e49;
      box-shadow: 0 0 20px rgba(0,0,0,0.5);
    }

    &::after {
      content: "";
      position: absolute;
      display: none;
      opacity: 0;
      top: -18px;
      left: 15px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background-color: #2b3e49;
    }

    &:hover::before,
    &:hover::after {
      display: block;
      opacity: 1;
    }
  }

  // Clicked button
  &--clicked {
    animation: btnClicked 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    backface-visibility: visible;
  }

  // Cancel deploy button - Deployment Details page
  &--cancel-deploy {
    max-width: 80px;
    height: 22px;
    width: 100%;
    font-size: 12px;
    margin-left: 10px;
  }

  &--dark  { 
    background-color: #2f404a;

    &:hover { background-color: lighten(#2f404a, 5%)}
  }

  &--darker {
    background-color: #16242c;

    &:hover { background-color: lighten(#16242c, 5%)}
  } 
  &--deepdark { 
    background-color: #24333b;

    &:hover { background-color: lighten(#24333b, 5%)}
  }

  &--blue  { 
    background-color: $blue;

    &:hover { background-color: lighten($blue, 5%)}
  }
  &--lightBlue { 
    background-color: $lightBlue;

    &:hover { background-color: lighten($lightBlue, 5%)}
}
  &--green { 
    background-color: $green;
    
    &:hover { background-color: darken($green, 5%)}
  }
  
  &--lightGreen { 
    background-color: $light-green;

    &:hover { background-color: lighten($light-green, 5%)}
  }
  &--danger { 
    background-color: $red;

    &:hover { background-color: lighten($red, 5%)}
  }

  // Amazon / Git account buttons
  &--orange { 
    background-color: $amazon-yellow;

    &:hover { background-color: lighten($amazon-yellow, 5%)}
  }

  &--git    { 
    background-color: $git-red; 

    &:hover { background-color: lighten($git-red, 5%)}
  }

  &--git-hub   { 
    background-color: $git-hub; 
    &:hover { background-color: darken($git-hub, 5%)}
  }
}

@keyframes btnClicked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}