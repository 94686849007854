.timeline {
  padding: 25px 5px;
  padding-bottom: 0;

  // &__container {
  //   // max-height: 670px;
  //   // overflow-y: auto;
  // }

  &__item { margin-bottom: 25px; }

  &__info {
    margin-left: 20px;
    padding-right: 10px;

    h5 {
      font-size: 15px;
      font-weight: 700;
    }

    p { 
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  &__circle { 
    position: relative;
    flex-shrink: 0;

    &::before {
      content: "";
      position: absolute;
      top:  100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0.5px;
      height: 60px;
      background-image: linear-gradient(to bottom, transparent 50%, #414d54 50%);
      background-size: 100% 10px;
    }
  }

  // Don't display last timeline item's circle border
  &__item:last-of-type &__circle::before { display: none; }
}
