.dashboard {
    padding: 10px 0;
    width: calc(100% + 17px);
    .widget {
        margin: 15px 0;
        padding: 20px;
        background-color: #24323b;
        border-radius: 5px;
        min-height: 230px;

        .placeholder {
            text-align: center;
            margin-top: 40px;
            .btn {
                margin: auto;
            }
            p {
                a {
                    font-size: inherit;
                    color: $lightBlue;
                }
                i {
                    color: $green;
                }
            }
        }
        
        .widget-header {
            margin-bottom: 15px;
            .widget-title {
                color: #ffffff
            }
            .widget-subtitle {
                color: #a5b8ca;
                font-size: 14px;
            }
        }

        &.widget-metrics {
            .widget-header {
                .badge {
                    margin-left: auto;
                }
            }
            .widget-content {
                margin: 35px;
                h6 {
                    color: #a5b8ca;
                }
            }
            .widget-footer {
                .footer-title {
                    color: #a5b8ca;                
                }
                .metric-trend {
                    margin-left: auto;
                    &.trend--success {
                        color: $green;
                    }
                    &.trend--fail {
                        color: $red;
                    }
                }
                margin-left: auto;
            }
        }

        &.widget-table {
            .table {
                width: 100%;
                margin: 0;

                th {
                    padding: 10px 10px;
                }

                td {
                    padding: 10px 10px;
                }

                & tbody tr td {
                    &:first-child::after {
                        right: 0;
                        left: inherit;
                        width: 100%;
                      }
                  
                      &:last-child::after { width: 100%; }
                }
            }
        }

        &.widget-chart {
            .content-header {
                margin: 22px 0;
                color: #a5b8ca;
                .header-value {
                    margin: 7px 0;
                }
                .header-subtitle {
                    &.subtitle--success {
                        color: $green;
                    }
                    &.subtitle--fail {
                        color: $red;
                    }
                }
            }
            .placeholder {
                margin-top: 25px;
            }
        }
    }
}