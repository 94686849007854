// PAGE CONTENT COMPONENT
.page-content {
  margin: 20px 0 30px 0;

  & .card { padding: 30px;}
  & .card.light { 
      padding: 15px; 
      display: flex;
      flex-direction: column;
      min-height: 100%;

      @media (max-width: 1200px) {
        min-height: 300px;
      }
  }
  & .card.dark.compact { 
    padding: 15px; 
    // display: flex;
    // flex-direction: column;
    min-height: 100%;

    @media (max-width: 1200px) {
      min-height: 300px;
    }
  }

  &--stages {
    & .card.dark { padding: 0;}
  }

  & .card--underline { 
    padding: 20px 30px 10px 30px;
    
    & .card__header::before { display: none; }
  }

  &__title {
      padding: 20px 0;
      border-bottom: 1px solid #24323b;
      margin-bottom: 20px;
  }

  &__text {
      color: $txt-white;
      font-size: 16px;
      margin-bottom: 30px;

      a {
          font-size: inherit;
          color: $lightBlue;
      }

      
    &--stage {
        max-width: 512px;
        font-size: 18px;
        text-align: center;
        margin: 5px 0 40px 0;
    }
  }

  & .input__field::placeholder { font-size: 13px; }

  &__content-container {
      max-width: 710px;
      width:     100%;
      margin: 0 auto;

      & .btn--xl { 
          max-width: 280px;
          font-size: 16px;
      }
      
      & .btn--lg {
          max-width: 235px;
          width: 100%;
          height: 55px;
          font-size: 18px;
      }
  }

  &__dropdown-container { 
      width: 100%; 
      margin-top: 15px;
      padding-left: 15px;
      padding-right: 15px;

      // Override dropdown style
      & .dropdown { 
            max-width: 100%;
            height: 77px;
        }
  }

  &__info {
      display: flex;
      align-items: flex-start;
      margin-top: 15px;

      & svg {
          max-width: 15px;
          width: 100%;
          height: 20px;
          margin-right: 5px;
          color: #abbed0;
      }
  }

  & .input__field { 
      height: 48px; 
      margin-bottom: 4px;
  }

  // Override medium sized buttons
  & .btn--md { max-width: 155px; }

  // Override CardHeader properties
  & .card__header {
      width: 100%;
      padding: 0 10px 15px 10px;
      position: relative;
      
      & .card__title {
          font-size: 22px;
          font-weight: bold;
      }

      &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: calc(100% + 30px);
          margin: 0 -15px;
          height: 1px;
          background-color: #465762;
      }
  }
  & .card_header_container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & .card__header::before { width: calc(100% + 30px); }
  }


  &__error {
    color: #f84737;
    font-size: 12px;
  }
}